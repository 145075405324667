import React, { FC, useEffect, useState } from 'react';

// ANTD
import { Button, Divider, message, Modal, Popconfirm, Select, Typography } from 'antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable, DragDropContextProps } from 'react-beautiful-dnd';

import Seo from '../../components/Seo';

// API Types
import { WebSerie as WebSerieApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    listWebSeriesSelections,
    webSeriesSelectionsToList,
    updateWebSeriesSelections as updateWebSeriesSelectionsApiCall,
    getWebSeriesSelectionsState,
    getWebSeriesSelectionsToListState,
    getUpdateWebSeriesSelectionsState,
    SelectionsState,
} from '../../store/actions/selections';

// Helpers
import { usePrevious } from '../../hooks';

// Style
import '../../assets/styles/WebSeriesSelections.less';

export interface SelectionsItem {
    id: string;
    name: string;
}

const reorder = (list: SelectionsItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export interface WebSeriesSelectionsProps {
    listWebSeriesSelectionsState: SelectionsState['listWebSeriesSelections'];
    webSeriesSelectionsToListState: SelectionsState['webSeriesSelectionsToList'];
    updateWebSeriesSelectionsState: SelectionsState['updateWebSeriesSelections'];
    loadWebSeriesSelections: typeof listWebSeriesSelections.trigger;
    loadWebSeriesSelectionsToList: typeof webSeriesSelectionsToList.trigger;
    updateWebSeriesSelections: typeof updateWebSeriesSelectionsApiCall.trigger;
}

const WebSeriesSelections: FC<WebSeriesSelectionsProps> = ({
    listWebSeriesSelectionsState,
    updateWebSeriesSelectionsState,
    webSeriesSelectionsToListState,
    loadWebSeriesSelections,
    updateWebSeriesSelections,
    loadWebSeriesSelectionsToList,
}) => {
    const [items, setItems] = useState<SelectionsItem[]>([]);
    const [webSerieIdSelected, setWebSerieIdSelected] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [webSerieToAdd, setWebSerieToAdd] = useState<WebSerieApiType[]>([]);

    const previous = usePrevious({
        listWebSeriesSelectionsState,
        updateWebSeriesSelectionsState,
        webSeriesSelectionsToListState,
    });

    const options = webSerieToAdd.map((webSerie) => <Select.Option key={webSerie.id} value={webSerie.name}>{webSerie.name}</Select.Option>);

    const onClickRemoveButton = (index: number) => {
        const newItems = items.slice(0, index).concat(items.slice(index + 1, items.length));
        setItems(newItems);
        const itemsToUpdate = [];
        for (let i = 0; i < newItems.length; i++) {
            const item = {
                id: newItems[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        updateWebSeriesSelections({
            selections: itemsToUpdate,
        });
    };

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index,
        );
        setItems(newItems);

        const itemsToUpdate = [];
        for (let i = 0; i < newItems.length; i++) {
            const item = {
                id: newItems[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        updateWebSeriesSelections({
            selections: itemsToUpdate,
        });
    };

    const onAddWebSerieButton = () => {
        setIsModalVisible(true);
        loadWebSeriesSelectionsToList();
    };

    const onSelect = (value: any, element: any) => {
        console.log('selected :', element);
        setWebSerieIdSelected(element.key);
    };

    const onOkAddWebSerieButton = () => {
        setIsModalVisible(false);
        if (items.some((item) => item.id === webSerieIdSelected)) {
            message.warning('Cette web-série est déjà dans la selection');
            return;
        }
        const itemsToUpdate = [];
        let i = 0;
        for (i = 0; i < items.length; i++) {
            const item = {
                id: items[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        const itemToAdd = {
            id: webSerieIdSelected,
            index: i === 0 ? 0 : i + 1,
        };
        itemsToUpdate.push(itemToAdd);
        updateWebSeriesSelections({
            selections: itemsToUpdate,
        });
        setWebSerieIdSelected('');
    };

    const onCancelAddWebSerieButton = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (previous?.listWebSeriesSelectionsState.loading && !listWebSeriesSelectionsState.loading) {
            const newItems = [];
            for (const webSerie of listWebSeriesSelectionsState.data.items) {
                const newItem = {
                    id: webSerie.id,
                    name: webSerie.name,
                };
                newItems.push(newItem);
            }
            setItems(newItems);
        }

        if (previous?.updateWebSeriesSelectionsState.loading && !updateWebSeriesSelectionsState.loading) {
            loadWebSeriesSelections({
                sort: 'selection.index',
                sortOrder: 'asc',
            });
        }
    }, [loadWebSeriesSelections, previous, listWebSeriesSelectionsState, updateWebSeriesSelectionsState]);

    useEffect(() => {
        if (previous?.webSeriesSelectionsToListState.loading && !webSeriesSelectionsToListState.loading) {
            const webSeriesArray = [];
            for (const webSerie of webSeriesSelectionsToListState.data.items) {
                if (webSerie.isOnline) {
                    webSeriesArray.push(webSerie);
                }
            }
            setWebSerieToAdd(webSeriesArray);
        }
    }, [previous, webSeriesSelectionsToListState]);

    useEffect(() => {
        loadWebSeriesSelections({
            sort: 'selection.index',
            sortOrder: 'asc',
        });
    }, [loadWebSeriesSelections]);

    return (
        <>
            <Seo title="Sélections de web-séries" />
            <div className="page-header">
                <Divider />
                <Typography.Title level={1}>Carrousel des web-series</Typography.Title>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        className="droppable-webseries"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                    {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                            <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="draggable-webseries"
                            >
                                <MenuOutlined className="menu-icon"/>
                                <p className="webseries-name">{item.name}</p>
                                <Popconfirm
                                    placement="topLeft"
                                    title={'Êtes-vous sûr de supprimer cette web-série de la selection ?'}
                                    onConfirm={onClickRemoveButton.bind(null, index)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        className="delete-button"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                    />
                                </Popconfirm>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>

            <div className="webserie-add-button-div">
                <Button
                    type="dashed"
                    size="large"
                    className="webserie-add-button"
                    onClick={onAddWebSerieButton}
                    block
                >
                    Ajouter une web-serie
                </Button>
            </div>

            <Modal
                className="webserie-selection-modals"
                title="Ajouter une web-serie"
                centered
                visible={isModalVisible}
                okText="Valider"
                cancelText="Annuler"
                onOk={onOkAddWebSerieButton}
                onCancel={onCancelAddWebSerieButton}
            >
                <Select
                    className="input-webserie-selection"
                    showSearch
                    placeholder="Sélectionner une web-serie"
                    defaultActiveFirstOption={false}
                    onSelect={onSelect}
                >
                    {options}
                </Select>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listWebSeriesSelectionsState: getWebSeriesSelectionsState(state),
    updateWebSeriesSelectionsState: getUpdateWebSeriesSelectionsState(state),
    webSeriesSelectionsToListState: getWebSeriesSelectionsToListState(state),
});

export default connect(
    mapStateToProps,
    {
        loadWebSeriesSelections: listWebSeriesSelections.trigger,
        updateWebSeriesSelections: updateWebSeriesSelectionsApiCall.trigger,
        loadWebSeriesSelectionsToList: webSeriesSelectionsToList.trigger,
    },
)(WebSeriesSelections);
