import { combineReducers } from 'redux';

import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { TestState, testReducer } from '../actions/test';
import { UsersState, usersReducer } from '../actions/users';
import { StatsState, statsReducer } from '../actions/stats';
import { PurchasesState, purchasesReducer } from '../actions/purchases';
import { EditorsState, editorsReducer } from '../actions/editors';
import { CategoriesState, categoriesReducer } from '../actions/categories';
import { SelectionsState, selectionsReducer } from '../actions/selections';
import { WebSerieState, webSerieReducer } from '../actions/webseries';
import { VideoState, videosReducer } from '../actions/videos';
import { LandingState, landingReducer } from '../actions/landing';
import { promoReducer, PromoState } from '../actions/promoCode';
export interface MainReducerState {
    readonly auth: AuthState;
    readonly cookies: CookiesState;
    readonly test: TestState;
    readonly users: UsersState;
    readonly stats: StatsState;
    readonly purchases: PurchasesState;
    readonly editors: EditorsState;
    readonly categories: CategoriesState;
    readonly selections: SelectionsState;
    readonly webSerie: WebSerieState;
    readonly videos: VideoState;
    readonly landing: LandingState;
    readonly promo: PromoState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    cookies: cookiesReducer,
    test: testReducer,
    users: usersReducer,
    stats: statsReducer,
    purchases: purchasesReducer,
    editors: editorsReducer,
    categories: categoriesReducer,
    selections: selectionsReducer,
    webSerie: webSerieReducer,
    videos: videosReducer,
    landing: landingReducer,
    promo: promoReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
