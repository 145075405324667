import React, { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

// ANTD
import { Typography, Badge, Divider, Row, Button, Col, message, Drawer, Form, Input, Select, DatePicker, Modal } from 'antd';
import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { SearchOutlined, CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';

// API Types
import { Promo as PromoApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {list as webSeriesApiCall, WebSerieState, getListWebSeriesState} from '../../store/actions/webseries';
import {
    list,
    remove,
    details,
    PromoState,
    getPromoDetailState,
    getPromoListState,
    getPromoRemoveState,
    create as createApiCall,
    getPromoCreateState,
    update as updateApiCall,
    getPromoUpdateState,
    exportPromo as exportApiCall,
    getPromoExportState,

} from '../../store/actions/promoCode';

// Helpers
import { usePrevious } from '../../hooks';
// Style
import '../../assets/styles/ClientsList.less';
import { downloadFile } from '../../helpers';

export interface PromoListProps {
    promos: PromoState['list'];
    loadPromo: typeof list.trigger;
    removeState: PromoState['remove'];
    removePromo: typeof remove.trigger;
    detailsState: PromoState['details'];
    detailsPromo: typeof details.trigger;
    resetDetailsPromo: typeof details.reset;
    webSeriesList: WebSerieState['list'];
    getWebSeriesList: typeof webSeriesApiCall.trigger;
    create: PromoState['create'];
    createPromo: typeof createApiCall.trigger;
    updateState: PromoState['update'];
    updatePromo: typeof updateApiCall.trigger;
    promoExportState: PromoState['exportPromo'];
    getExport: typeof exportApiCall.trigger;
}

const PromoList: FC<PromoListProps> = ({
    promos, loadPromo, removeState, removePromo, detailsState, detailsPromo,
    resetDetailsPromo, webSeriesList, getWebSeriesList, create, createPromo, updateState, updatePromo,
    promoExportState , getExport,
}) => {
    const [isVisibleDrawer, setIsVisibleDrawer] = useState<boolean>(false);

    const previous = usePrevious({
        removeState,
        create,
        detailsState,
        updateState,
        promoExportState,
    });
    const [formState] = Form.useForm();

    const defaultSortOptions = {
        sort: 'createdAt',
        sortOrder: 'desc',
    };
    useEffect(() => {
        loadPromo(defaultSortOptions);
        getWebSeriesList({
            minimumPrice: 1,
        });
        // eslint-disable-next-line
    }, [loadPromo, getWebSeriesList]);

    useEffect(() => {
        if (removeState.error && previous?.removeState.loading) {
            message.error('Impossible de supprimer cette promotion');
        }
        if (previous?.removeState.loading === true && ! removeState.error) {
            loadPromo(defaultSortOptions);
        }
        // eslint-disable-next-line
    }, [removeState, previous, loadPromo]);

    useEffect(() => {
        if (create.error && previous?.create.loading) {
            message.error('Impossible de créer cette promotion');
        }
        if (previous?.create.loading === true && ! create.error) {
            setIsVisibleDrawer(false);
            loadPromo(defaultSortOptions);
            message.success('Promotion créée');
        }
        // eslint-disable-next-line
    }, [create, previous, loadPromo]);
    useEffect(() => {
        if (previous?.detailsState.loading === true && ! detailsState.error) {
            if (detailsState.data) {
                formState.setFieldsValue({
                    ...detailsState.data,
                    expirationDate: [moment(detailsState.data.expirationStartDate), moment(detailsState.data.expirationEndDate)],
                    webSerie: detailsState.data.webSerie.name,
                });
            }
            setIsVisibleDrawer(true);
        }
    }, [detailsState, previous, formState]);
    useEffect(() => {
        if (previous?.updateState.loading === true && ! updateState.error) {
            loadPromo(defaultSortOptions);
            message.success('Promotion mis à jour');
            resetDetailsPromo();
            formState.resetFields();
            setIsVisibleDrawer(false);
        }
        // eslint-disable-next-line
    }, [updateState, previous, formState, loadPromo, resetDetailsPromo ]);

    useEffect(() => {
        if (!promoExportState.loading && previous?.promoExportState.loading && !promoExportState.error) {
            downloadFile(promoExportState.data, `export_lyr_promo_${moment().format('YYYYMMDD')}.csv`);
        }
    }, [previous, promoExportState]);

    const onSearch = (value: string) => {
        if (!value || !value.length) {
            loadPromo(defaultSortOptions);
        } else {
            loadPromo({
                search: value,
            });
        }
    };

    const onExport = () => {
        getExport();
    };

    const listWebSerieOption = webSeriesList.data.items.map((webSerie) =>
    (
        <Select.Option key={webSerie.id} value={webSerie.id}>
            {webSerie.name}
        </Select.Option>
    ));
    const columns: Array<ColumnProps<PromoApiType>> = [
        {
            title: 'Code',
            dataIndex: 'code',
        },
        {
            title: 'Société / partenaire',
            dataIndex: 'company',
        },
        {
            title: 'Série',
            dataIndex: ['webSerie', 'name'],
        },
        {
            title: 'Début de validité',
            dataIndex: 'expirationStartDate',
            render: (expirationEndDate: string) => {
                if (!expirationEndDate) {
                    return '-';
                }
                return moment(expirationEndDate).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Fin de validité',
            dataIndex: 'expirationEndDate',
            render: (expirationEndDate: string) => {
                if (!expirationEndDate) {
                    return '-';
                }
                return moment(expirationEndDate).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Actif',
            render: (_, promo) => {
                return !moment().isBetween(moment(promo.expirationStartDate), moment(promo.expirationEndDate))
                || promo.quantityUsed >= promo.quantity ? 'Non' : 'Oui';
            },
            filters: [
                {
                    text: 'Oui',
                    value: true,
                },
                {
                    text: 'Non',
                    value: false,
                },
            ],
            onFilter: (value, promo) => {
                if (value === false) {
                    return !moment().isBetween(moment(promo.expirationStartDate), moment(promo.expirationEndDate))
                    || promo.quantityUsed >= promo.quantity;
                } else {
                    return !(!moment().isBetween(moment(promo.expirationStartDate), moment(promo.expirationEndDate))
                    || promo.quantityUsed >= promo.quantity);
                }
            },
            filterMultiple: false,
        },
        {
            title: 'Utilisateurs',
            render: (value, record) => {
                return record.quantityUsed.toString() + '/' + record.quantity.toString();
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (value, record) => {
                return (
                <>
                    <Button shape="circle" onClick={onCopy.bind(null, record.code)}><CopyOutlined /></Button>
                    <Button shape="circle" onClick={onEdit.bind(null, value)}><EditOutlined /></Button>
                    <Button shape="circle" onClick={onDelete.bind(null, value)}><DeleteOutlined /></Button>
                </>
                );
            },
        },
    ];

    const onAddPromo = () => {
        setIsVisibleDrawer(true);
        formState.resetFields();
    };

    const onCopy = (code: string) => {
        navigator.clipboard.writeText(code);
        message.info('Code copié !');
    };

    const onDelete = (id: string) => {
        Modal.confirm({
            content: 'Etes vous certain de vouloir supprimer cette promotion ?',
            onOk() {
                removePromo(id);
              },
        });
    };

    const onEdit = (id: string) => {
        detailsPromo(id);
    };
    const onClose = () => {
        setIsVisibleDrawer(false);
        resetDetailsPromo();
    };

    const onFinish = (values: any) => {
        if (detailsState.data) {
            updatePromo({
                id: detailsState.data.id,
                data: {
                    quantity: values.quantity,
                    expirationStartDate: values.expirationDate[0],
                    expirationEndDate: values.expirationDate[1],
                },
            });
        } else {
            createPromo({...values, expirationStartDate: values.expirationDate[0], expirationEndDate: values.expirationDate[1]});
        }
    };

    const disablePastDate = (current: Moment) => current.isBefore(moment().subtract(1, 'day'));
    const {RangePicker} = DatePicker;
    const itemsPerPage = 20;

    const onTableChange: TableProps<PromoApiType>['onChange'] = (pagination, filters, sorter: any) => {
        let page = 0;
        if (pagination.current) {
            page = pagination.current - 1;
        }
        loadPromo({
            ...defaultSortOptions,
            page,
            pageSize: pagination.pageSize,
        });
    };

    return (
        <>
            <Seo title="Codes promo" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={12}>
                        <Typography.Title level={1}>Codes promo <Badge count={promos.data.totalCount}/></Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            type="primary"
                            onClick={onAddPromo}
                        >
                            Ajouter un code
                        </Button>
                        <Button
                            className="header-button"
                            type="primary"
                            onClick={onExport}
                        >
                            Export
                        </Button>
                    </Col>
                </Row>
                <Search
                    size="large"
                    placeholder="Rechercher un code / une société / un partenaire / une série"
                    prefix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>
            <Table<PromoApiType>
                className="clients-list-table"
                columns={columns}
                loading={promos.loading}
                dataSource={promos.data.items}
                onChange={onTableChange}
                pagination={{
                    total: promos.data.totalCount,
                    pageSize: itemsPerPage,
                    hideOnSinglePage: true,
                }}
            />
            <Drawer
                title={(detailsState.data ? 'Editer le ' : 'Créer un ') + 'code promo'}
                placement="right"
                onClose={onClose}
                visible={isVisibleDrawer}
                width={680}
            >
                <Form
                    layout="vertical"
                    hideRequiredMark
                    name="promo"
                    onFinish={onFinish}
                    form={formState}
                >
                <Form.Item
                    label="Société / partenaire"
                    name="company"
                    rules={[{ required: true, message: 'Veuillez saisir une société' }]}
                >
                    <Input disabled={detailsState.data !== undefined}/>
                </Form.Item>

                <Form.Item
                    label="Pourcentage à appliquer"
                    name="discount"
                    initialValue={1}
                    rules={[{ required: true, message: 'Veuillez saisir un pourcentage' }]}
                >
                    <Input type="number" min={1} max={100} addonAfter="%" disabled={detailsState.data !== undefined}/>
                </Form.Item>

                <Form.Item
                    label="Dates de validité"
                    name="expirationDate"
                    rules={[
                        { required: true, message: 'Veuillez saisir les dates de validité' },
                        {
                            validator: async (_, value: Date[]) => {
                            console.log(value);
                            if (moment(value[1]).isSameOrAfter(moment().endOf('day'))) {
                                if (moment(value[0]).isBefore(moment(value[1]))) {
                                    return Promise.resolve();
                                }
                            }
                            return Promise.reject('Vous ne pouvez pas renseigner une date antérieur à celle du jour');
                            },
                            validateTrigger: 'onBlur',
                        },
                    ]}
                >
                        <RangePicker disabledDate={disablePastDate} format={'DD/MM/YYYY'} />
                </Form.Item>

                <Form.Item
                    label="Nombre maximum d'utilisateur"
                    name="quantity"
                    initialValue={1}
                    rules={[{ required: true, message: 'Veuillez saisir un nombre maximum d\'utilisateur' }]}
                >
                        <Input type="number" min={1}/>
                </Form.Item>

                <Form.Item
                    label="Série à laquelle appliquer le code"
                    name="webSerie"
                    rules={[{ required: true, message: 'Veuillez saisir une série' }]}
                >
                    {detailsState.data
                    ?
                    (<Input disabled={detailsState.data !== undefined} />)
                    :
                    (
                    <Select placeholder="Sélectionner un série">
                        {listWebSerieOption}
                    </Select>
                    )
                    }
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                    {detailsState.data ? 'Modifier' : 'Créer'} le code promo
                    </Button>
                </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    promos: getPromoListState(state),
    removeState: getPromoRemoveState(state),
    detailsState: getPromoDetailState(state),
    webSeriesList: getListWebSeriesState(state),
    create: getPromoCreateState(state),
    updateState: getPromoUpdateState(state),
    promoExportState: getPromoExportState(state),
});

export default connect(
    mapStateToProps,
    {
        loadPromo: list.trigger,
        removePromo: remove.trigger,
        detailsPromo: details.trigger,
        resetDetailsPromo: details.reset,
        getWebSeriesList: webSeriesApiCall.trigger,
        createPromo: createApiCall.trigger,
        updatePromo: updateApiCall.trigger,
        getExport: exportApiCall.trigger,
    },
)(PromoList);
