import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeSimpleAction } from '../helpers/EzeeSimpleAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User } from '../api/apiTypes';
import { MainReducerState } from '../reducers';

import {
    LoginPayload,
    login as loginApiCall,
    logout as logoutApiCall,
    checkLoginStatus as checkLoginStatusApiCall,
} from '../api/auth';

// State

export interface AuthState {
    user?: User;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
    error?: any;
}

const initialState: AuthState = {
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
};

// Actions/Reducers

export const login = new EzeeAsyncAction<AuthState, LoginPayload, User>('auth/login', initialState, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
        error: payload,
    }),
    reset: (state) => initialState,
});

export const checkLoginStatus = new EzeeAsyncAction<AuthState, any, User>('auth/checkLoginStatus', initialState, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        user: payload,
        hasCheckedLoginStatus: true,
        isConnected: true,
        loading: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

export const logout = new EzeeAsyncAction<AuthState, any, any, any>('auth/logout', initialState, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    failure: (state, payload) => ({
        hasCheckedLoginStatus: true,
        isConnected: false,
        loading: false,
    }),
    reset: (state) => ({
        ...initialState,
    }),
});

// Reducer

export const authReducer = EzeeSimpleAction.mergeActionReducers<AuthState>([
    login,
    checkLoginStatus,
    logout,
]);

// Saga

export function* authSaga() {
    yield takeLatest(login.type.trigger, simpleAsyncSaga(loginApiCall, login));
    yield takeLatest(checkLoginStatus.type.trigger, simpleAsyncSaga(checkLoginStatusApiCall, checkLoginStatus));
    yield takeLatest(logout.type.trigger, simpleAsyncSaga(logoutApiCall, logout));
}

// Store helpers

export const getAuthState = (state: MainReducerState) => state.auth;
export const getUser = (state: MainReducerState) => state.auth.user;
