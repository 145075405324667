import { Landing } from './apiTypes';
import { SearchPaginationQuery } from '.';
import api from './_client';
import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

export interface LandingUploadPayload {
    file: RcFile;
    type: 'logo';
    body: any;
}

export type LandingListPayload = SearchPaginationQuery;

export const details = () => api.get(`/landingPage`);

export const remove = (payload: Landing) => {
    return api.delete(`/landingPage/${payload.id}`);
};

export const upload = (payload: LandingUploadPayload) => {
    const formData = serialize(payload.body, {indices: true, noFilesWithArrayNotation: true});
    return api.post(`/landingPage`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
