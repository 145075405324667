import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { usersSaga } from '../actions/users';
import { statsSaga } from '../actions/stats';
import { purchasesSaga } from '../actions/purchases';
import { editorsSaga } from '../actions/editors';
import { categoriesSaga } from '../actions/categories';
import { selectionsSaga } from '../actions/selections';
import { webSerieSaga } from '../actions/webseries';
import { videosSaga } from '../actions/videos';
import { landingSaga } from '../actions/landing';
import { promoSaga } from '../actions/promoCode';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(usersSaga);
    yield fork(statsSaga);
    yield fork(purchasesSaga);
    yield fork(editorsSaga);
    yield fork(categoriesSaga);
    yield fork(selectionsSaga);
    yield fork(webSerieSaga);
    yield fork(videosSaga);
    yield fork(landingSaga);
    yield fork(promoSaga);
}
