import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/App.less';
import './assets/styles/Fonts.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import CookieBanner from './components/CookieBanner';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import Privacy from './pages/privacy';
import ClientsList from './pages/clients';
import PurchasesList from './pages/purchasesList';
import PurchasesDetails from './pages/purchaseDetails';
import Editors from './pages/editors';
import Categories from './pages/categories';
import WebSeriesSelections from './pages/webSeriesSelections';
import VideoSlections from './pages/videosSelections';
import WebSeries from './pages/webseries';
import { getRawRoute, RoutePathName } from './routes';
import Landing from './pages/landing';
import Promos from './pages/promos';

const App: FC = () => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <CookieBanner />
            <MainLayout>
                <ErrorBoundary>
                    <Switch>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.home)}
                        >
                            <Home />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.users)}
                        >
                            <ClientsList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.privacy)}
                        >
                            <Privacy />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.userDetails)}
                        >
                            <PurchasesList />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.purchasesDetails)}
                        >
                            <PurchasesDetails />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.editors)}
                        >
                            <Editors />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.webSeriesSelections)}
                        >
                            <WebSeriesSelections />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.videosSelections)}
                        >
                            <VideoSlections />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.categories)}
                        >
                            <Categories />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.webSeries)}
                        >
                            <WebSeries />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.landing)}
                        >
                            <Landing />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.promo)}
                        >
                            <Promos />
                        </Route>

                        <Route path="*">
                            <ErrorPage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

export default App;
