export function shortCivility(gender: string) {
    let value = '-';
    switch (gender) {
        case 'Male':
            value = 'M.';
            break;
        case 'Female':
            value = 'Mme.';
            break;
        default:
            break;
    }
    return value;
}
