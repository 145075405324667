import React, { FC, useEffect, useState } from 'react';

// ANTD
import { Divider, Typography, Badge, Row, Col, Button, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { TableProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';

// Api Types
import { WebSerie as WebSeriesApiType, Editor as EditorApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    list as listWebSeries,
    WebSerieState,
    getListWebSeriesState,
    getUpdateWebSeriesState,
} from '../../store/actions/webseries';
import { list as listEditors, EditorsState, getEditorsListState } from '../../store/actions/editors';

// Helpers
import { usePrevious } from '../../hooks';

// Component
import DetailsWebSerie from './DetailsWebSerie';

// Style
import '../../assets/styles/WebSeries.less';

export interface EditorFilter {
    text: string;
    value: string;
}

export interface WebSeriesProps {
    list: WebSerieState['list'];
    updateWebSerie: WebSerieState['update'];
    loadWebSerie: typeof listWebSeries.trigger;
    listOfEditors: EditorsState['list'];
    loadEditors: typeof listEditors.trigger;
}

const WebSeries: FC<WebSeriesProps> = ({
    list,
    updateWebSerie,
    listOfEditors,
    loadWebSerie,
    loadEditors,
}) => {

    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [webSerieIdToEdit, setWebSerieIdToEdit] = useState<string>('');
    const [editorFilterList, setEditorFilterList] = useState<EditorFilter[]>([]);

    const rowKey = (item: WebSeriesApiType) => `${item.id}`;

    const previous = usePrevious({
        listOfEditors,
        updateWebSerie,
    });

    const onSearch = (value: string) => {
        if (!value || !value.length) {
            loadWebSerie({
                sort: 'index',
                sortOrder: 'asc',
            });
            setSearchInputValue('');
        } else {
            setSearchInputValue(value);
            loadWebSerie({
                search: value,
            });
        }
    };

    const openDrawer = (id: string) => {
        setWebSerieIdToEdit(id);
    };

    const resetId = () => {
        setWebSerieIdToEdit('');
    };

    const onTableChange: TableProps<WebSeriesApiType>['onChange'] = (pagination, filter, sorter: any) => {
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (!sortOrder || !sortOrder.length) {
            if (!searchInputValue) {
                loadWebSerie({
                    sort: 'index',
                    sortOrder: 'asc',
                });
            } else {
                loadWebSerie({
                    search: searchInputValue,
                });
            }
        } else {
            if (!searchInputValue) {
                loadWebSerie({
                    sort: sorter.field,
                    sortOrder,
                });
            } else {
                loadWebSerie({
                    sort: sorter.field,
                    sortOrder,
                    search: searchInputValue,
                });
            }
        }
    };

    const columns: Array<ColumnProps<WebSeriesApiType>> = [
        {
            title: 'Nom',
            dataIndex: 'name',
            sorter: true,
        },
        { title: 'Nb épisode', dataIndex: 'numberOfVideos' },
        {
            title: 'Editeur',
            dataIndex: 'editor',
            filters: editorFilterList,
            onFilter: (value, record) => record.editor?.name === value,
            render: (editor: EditorApiType) => {
                if (editor) {
                    return editor.name;
                }
                return '-';
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (id: string) => {
                return (
                    <div>
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={openDrawer.bind(null, id)}
                        />
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (previous?.listOfEditors.loading && !listOfEditors.loading) {
            const filterEditors = [];
            for (const editor of listOfEditors.data.items) {
                const editorFilterObject = {
                    text: editor.name,
                    value: editor.name,
                };
                filterEditors.push(editorFilterObject);
            }
            setEditorFilterList(filterEditors);
        }
        if (previous?.updateWebSerie.loading && !updateWebSerie.loading) {
            loadWebSerie({
                sort: 'index',
                sortOrder: 'asc',
            });
            setWebSerieIdToEdit('');
        }
    }, [loadWebSerie, previous, listOfEditors, updateWebSerie, setEditorFilterList]);

    useEffect(() => {
        loadWebSerie({
            sort: 'index',
            sortOrder: 'asc',
        });
        loadEditors();
    }, [loadWebSerie, loadEditors]);

    return (
        <>
            <Seo title="Liste des web-séries" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={24}>
                        <Typography.Title level={1}>Web-series <Badge count={list.data.totalCount}/></Typography.Title>
                    </Col>
                </Row>
                <Search
                    size="large"
                    placeholder="Rechercher une catégorie"
                    prefix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>

            <Table<WebSeriesApiType>
                className="webseries-list"
                columns={columns}
                rowKey={rowKey}
                loading={list.loading}
                dataSource={list.data.items}
                pagination={false}
                onChange={onTableChange}
            />

            <DetailsWebSerie
                id={webSerieIdToEdit}
                setId={resetId}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    list: getListWebSeriesState(state),
    updateWebSerie: getUpdateWebSeriesState(state),
    listOfEditors: getEditorsListState(state),
});

export default connect(
    mapStateToProps,
    {
        loadWebSerie: listWebSeries.trigger,
        loadEditors: listEditors.trigger,
    },
)(WebSeries);
