import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

import {
    details as detailsApiCall,
    upload as uploadApiCall,
    remove as removeApiCall,
    LandingUploadPayload,
} from '../api/landing';
import { RequestState, MainReducerState } from '../reducers';
import { Landing } from '../api/apiTypes';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// State

export interface LandingState {
    details: RequestState<Landing | undefined>;
    upload: RequestState<Landing | undefined>;
    remove: RequestState<Landing | undefined>;
}

const initialState: LandingState = {
    details: {
        data: undefined,
        loading: false,
        success: false,
    },
    upload: {
        data: undefined,
        loading: false,
        success: false,
    },
    remove: {
        data: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const details = new EzeeAsyncAction<
    LandingState['details'],
    any,
    Landing
>('landing/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const upload = new EzeeAsyncAction<
    LandingState['upload'],
    LandingUploadPayload,
    Landing
>('landing/upload', initialState.upload, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.upload,
    }),
});

export const remove = new EzeeAsyncAction<
    LandingState['remove'],
    Landing,
    Landing
>('landing/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const landingReducer = combineReducers<LandingState>({
    details: details.reducer,
    upload: upload.reducer,
    remove: remove.reducer,
});

// Saga

export function* landingSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(upload.type.trigger, simpleAsyncSaga(uploadApiCall, upload));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
}

// Store helpers

export const getLandingState = (state: MainReducerState) => state.landing;
export const getLandingDetailsState = (state: MainReducerState) => state.landing.details;
export const getLandingUploadState = (state: MainReducerState) => state.landing.upload;
