import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { ListResponse, Promo } from '../api/apiTypes';

import { MainReducerState, RequestState } from '../reducers';

import {
    PromoListPayload,
    PromoIdPayload,
    details as detailsApiCall,
    list as listApiCall,
    remove as removeApiCall,
    create as createApiCall,
    update as updateApiCall,
    PromoCreatePayload,
    PromoUpdatePayload,
    exportPromo as exportApiCall,
} from '../api/promoCode';

// State
export interface PromoState {
    list: RequestState<ListResponse<Promo>>;
    details: RequestState<Promo | undefined>;
    remove: RequestState<{ [id: string]: Promo }>;
    create: RequestState<Promo | undefined >;
    update: RequestState<Promo | undefined >;
    exportPromo: RequestState<string>;
}

const initialState: PromoState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    details: {
        data: undefined,
        loading: false,
    },
    remove: {
        data: {},
        loading: false,
    },
    create: {
        data: undefined,
        loading: false,
    },
    update: {
        data: undefined,
        loading: false,
    },
    exportPromo: {
        data: '',
        loading: false,
    },
};

// Actions/Reducers
export const list = new EzeeAsyncAction<
    PromoState['list'],
    PromoListPayload,
    ListResponse<Promo>
>('Promos/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    PromoState['details'],
    PromoIdPayload,
    Promo
>('promos/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const remove = new EzeeAsyncAction<
    PromoState['remove'],
    PromoIdPayload,
    Promo
>('promos/remove', initialState.remove, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

export const create = new EzeeAsyncAction<
    PromoState['create'],
    PromoCreatePayload,
    Promo
>('promos/create', initialState.create, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const update = new EzeeAsyncAction<
    PromoState['update'],
    PromoUpdatePayload,
    Promo
>('promos/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const exportPromo = new EzeeAsyncAction<
    PromoState['exportPromo'],
    string
>('promos/export', initialState.exportPromo, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.exportPromo,
    }),
});

// Reducer
export const promoReducer = combineReducers<PromoState>({
    list: list.reducer,
    details: details.reducer,
    remove: remove.reducer,
    create: create.reducer,
    update: update.reducer,
    exportPromo: exportPromo.reducer,
});

// Saga
export function* promoSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(exportPromo.type.trigger, exportFileSaga);
}

function* exportFileSaga() {
    try {
        const response = yield call(exportApiCall);
        const blob = new Blob([response], { type: 'application/csv'});
        const url = window.URL.createObjectURL(blob);
        return yield put(exportPromo.success(url));
    } catch (error) {
        return yield put(exportPromo.failure(error));
    }
}

// Store helpers
export const getPromoState = (state: MainReducerState) => state.promo;
export const getPromoListState = (state: MainReducerState) => state.promo.list;
export const getPromoDetailState = (state: MainReducerState) => state.promo.details;
export const getPromoRemoveState = (state: MainReducerState) => state.promo.remove;
export const getPromoCreateState  = (state: MainReducerState) => state.promo.create;
export const getPromoUpdateState = (state: MainReducerState) => state.promo.update;
export const getPromoExportState = (state: MainReducerState) => state.promo.exportPromo;
