import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { Typography, Space, Divider, Row, Col, Button } from 'antd';

import Seo from '../../components/Seo';

import MainStats from './components/MainStats';
import WebSeriesStats from './components/WebSeriesStats';
import EditorsStats from './components/EditorsStats';

import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { exportStats as exportStatsCall, getExportStatsState, StatsState } from '../../store/actions/stats';

import { usePrevious } from '../../hooks';
import { downloadFile } from '../../helpers';

import '../../assets/styles/Home.less';

export interface HomeProps {
    exportStatsState: StatsState['exportStats'];
    getExportStats: typeof exportStatsCall.trigger;
}

const Home: FC<HomeProps> = ({
    exportStatsState, getExportStats,
}) => {

    const month = moment().format('MMMM YYYY');
    const currentMonth = month.charAt(0).toUpperCase()  + month.slice(1);

    const previous = usePrevious(exportStatsState);

    const onDowload = () => {
        getExportStats();
    };

    useEffect(() => {
        if (!exportStatsState.loading && previous?.loading && !exportStatsState.error) {
            downloadFile(exportStatsState.data, `export_lyr_${moment().format('YYYYMMDD')}.csv`);
        }
    }, [previous, exportStatsState]);
    return (
        <>
            <Seo title="Tableau de bord" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={12}>
                        <Typography.Title level={1}>Tableau de bord - {currentMonth}</Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            type="primary"
                            onClick={onDowload}
                            loading={exportStatsState.loading}
                        >
                            Exporter les données
                        </Button>
                    </Col>

                </Row>
            </div>
            <div className="home-page">
                <Space direction="vertical" size="large" style={{ width: '100%' }}>

                    <MainStats />

                    <WebSeriesStats />

                    <EditorsStats />

                </Space>
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    exportStatsState: getExportStatsState(state),
});

export default connect(
    mapStateToProps,
    {
        getExportStats: exportStatsCall.trigger,
    },
)(Home);

// export default Home;
