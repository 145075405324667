import { SearchPaginationQuery } from './';
import api from './_client';
import { WebSerie } from './apiTypes';

export type SelectionListPayload = SearchPaginationQuery;

export interface WebSeriesSelectionItemUpdatePayload {
    id: WebSerie['id'];
    selection: WebSerie['selection'];
}

export interface WebSeriesSelectionUpdatePayload {
    selections: WebSeriesSelectionItemUpdatePayload[];
}

export interface VideosSelectionItemUpdatePayload {
    id: WebSerie['id'];
    selection: WebSerie['selection'];
}

export interface VideosSelectionUpdatePayload {
    selections: VideosSelectionItemUpdatePayload[];
}

export const webSeriesSelectionList = (payload: SelectionListPayload) => {
    return api.get(`/webserieSelection/list`, { params: payload });
};

export const videoSelectionList = (payload: SelectionListPayload) => {
    return api.get(`/videoSelection/list`, { params: payload });
};

export const webSeriesSelection = (payload: SelectionListPayload) => {
    return api.get(`/webserieSelection`, { params: payload });
};

export const videosSelection = (payload: SelectionListPayload) => {
    return api.get(`/videoSelection`, { params: payload });
};

export const updateWebSeriesSelection = (payload: WebSeriesSelectionUpdatePayload) => {
    return api.put(`/webserieSelection`, payload);
};

export const updateVideosSelection = (payload: VideosSelectionUpdatePayload) => {
    return api.put(`/videoSelection`, payload);
};
