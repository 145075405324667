import api from './_client';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => api.post('/auth', payload);

export const logout = () => api.post('/auth/logout');

export const checkLoginStatus = () => api.get('/me');
