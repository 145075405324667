
import { Col, Form, Input, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { UploadImage } from './UploadFile';
import { FormInstance } from 'antd/lib/form';

export interface HeadlineBlock {
    title: string;
    numberBlock: string;
    nameImage: string;
    form: FormInstance<any>;
}

export const HeadlineBlock: FC<HeadlineBlock> = ({
   title, numberBlock, nameImage, form,
}) => {
    return (
        <>
            <Typography.Title level={4}>{title}</Typography.Title>
            <Row >
                <Col span={12}>
                    <Form.Item
                        name={['headline', numberBlock, 'title']}
                        label="Titre *"
                        rules={[{ required: true, message: 'Veuillez renseigner un titre ' }]}
                    >
                        <Input
                            placeholder="Saisir un titre"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['headline', numberBlock, 'subTitle']}
                        label="Sous-titre *"
                        rules={[{ required: true, message: 'Veuillez renseigner un sous-titre' }]}
                    >
                        <Input.TextArea
                            placeholder="Saisir un sous-titre"
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{paddingLeft: '32px'}}>
                    <Form.Item label="Image *" name={[nameImage]} rules={[{ required: true, message: 'Veuillez mettre une image' }]}>
                        <UploadImage
                            info={`Dimensions (Minimum/Conseillée) : 420px x 420px (Ratio: 1:1)`}
                            name={form.getFieldValue([nameImage, 'originalName'])}
                            url={form.getFieldValue([nameImage, 'url'])}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
