
import { Card, Divider, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FC } from 'react';
import { Block } from './Block';

export interface BlocksProps {
    form: FormInstance<any>;
    block1: boolean;
    block2: boolean;
    block3: boolean;
    setBlock1: (value: any) => void;
    setBlock2: (value: any) => void;
    setBlock3: (value: any) => void;
}

export const Blocks: FC<BlocksProps> = ({
    form, block1, block2, block3, setBlock1, setBlock2, setBlock3,
}) => {

    return (
        <>
            <Typography.Title className="title" level={2}>Blocs alternés</Typography.Title>
            <Card className="landing-card">
                <Block
                    title={'Bloc n° 1'}
                    blockNumber={'0'}
                    nameImage={'image1'}
                    check={block1}
                    setBlock1={setBlock1}
                    form={form}
                />
                <Divider/>
                <Block
                    title={'Bloc n° 2'}
                    blockNumber={'1'}
                    nameImage={'image2'}
                    check={block2}
                    setBlock1={setBlock2}
                    form={form}
                />
                <Divider/>
                <Block
                    title={'Bloc n° 3'}
                    blockNumber={'2'}
                    nameImage={'image3'}
                    check={block3}
                    setBlock1={setBlock3}
                    form={form}
                />

            </Card>
        </>
    );
};
