
import { Card, Col, Form, Input, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FC } from 'react';
import { UploadImage } from './UploadFile';

export interface MarketingBannerProps {
    form: FormInstance<any>;
}

export const MarketingBanner: FC<MarketingBannerProps> = ({
    form,
}) => {

    return (
        <>
            <Typography.Title className="title" level={2}>Bannière marketing</Typography.Title>
            <Card className="landing-card">
                <Row >
                    <Col span={12}>
                        <Form.Item
                            name={['marketingBanner', 'title']}
                            label="Titre *"
                            rules={[{ required: true, message: 'Veuillez renseigner un titre ' }]}
                        >
                            <Input
                                placeholder="Saisir un titre"
                            />
                        </Form.Item>
                        <Form.Item
                            name={['marketingBanner', 'subTitle']}
                            label="Sous-titre *"
                            rules={[{ required: true, message: 'Veuillez renseigner un sous-titre' }]}
                        >
                            <Input.TextArea
                                placeholder="Saisir un sous-titre"
                            />
                        </Form.Item>

                    </Col>
                    <Col span={12} style={{paddingLeft: '32px'}}>
                        <Form.Item
                            label="Image *"
                            name={['backgroundImage']}
                            rules={[{ required: true, message: 'Veuillez mettre une image' }]}
                        >
                            <UploadImage
                                info={`Dimensions (Minimum/Conseillé) : 1440px x 540px (Ratio: 8:3)`}
                                name={form.getFieldValue(['backgroundImage', 'originalName'])}
                                url={form.getFieldValue(['backgroundImage', 'url'])}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row >
                    <Col span={12}>
                        <Form.Item
                            name={['marketingBanner', 'url']}
                            label="URL de redirection (http://, mailto:, tel: …) *"
                            rules={[{ required: true, message: 'Veuillez renseigner un url' }]}
                        >
                            <Input
                                placeholder="Saisir un URL de redirection (http://, mailto:, tel: …)"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingLeft: '32px'}}>
                        <Form.Item
                            name={['marketingBanner', 'buttonText']}
                            label="Texte du bouton *"
                            rules={[{ required: true, message: 'Veuillez renseigner un sous-titre' }]}
                        >
                            <Input
                                placeholder="Saisir le Texte du bouton"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </>
        );
};
