import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Dropdown, Avatar, Menu, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import { logout as logoutAction, AuthState } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';
import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';

interface HeaderProps {
    authState: AuthState;
    logout: typeof logoutAction.trigger;
}

const Header: FC<HeaderProps> = ({ authState, logout }) => {
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(LayoutContext);
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={getRoute(RoutePathName.login)} onClick={logout}>Se déconnecter</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header id="main-header">
            <Button
                type="link"
                id="sidebar-collapse-button"
                onClick={setIsSidebarCollapsed.bind(null, !isSidebarCollapsed)}
            >
                {isSidebarCollapsed ?
                    <MenuUnfoldOutlined className="trigger" /> :
                    <MenuFoldOutlined className="trigger" />
                }
            </Button>
            {(authState.user && authState.user.firstName) && (
                <Dropdown overlayClassName="user-card-dropdown" trigger={['click']} overlay={menu}>
                    <Link to="" className="user-card">
                        <Avatar size={32}>{authState.user?.firstName.substr(0, 1)}</Avatar>
                        {authState.user?.firstName}
                    </Link>
                </Dropdown>
            )}
        </Layout.Header>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: state.auth,
});

export default connect(
    mapStateToProps,
    {
        logout: logoutAction.trigger,
    },
)(Header);
