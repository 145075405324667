import { SearchPaginationQuery } from './';
import api from './_client';

export interface UserDetailsPayload {
    userId: string;
}

export type UserListPayload = SearchPaginationQuery;

export const details = (payload: UserDetailsPayload) => api.get(`/users/${payload.userId}`);

export const list = (payload: UserListPayload = {}) => {
    return api.get(`/users`, { params: payload });
};

export const exportUsers = () => api.get(`/exportUsers`, { responseType: 'blob' });
