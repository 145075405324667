import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Layout, Form, Input, Button, Alert } from 'antd';

import '../../assets/styles/Login.less';

import { AuthState, login, getAuthState } from '../../store/actions/auth';
import { MainReducerState } from '../../store/reducers';

import Seo from '../../components/Seo';
import { Breakpoint } from 'react-socks';

interface LoginProps extends RouteComponentProps {
    onLogin: typeof login.trigger;
    authState: AuthState;
}

const Login: FC<LoginProps> = ({ authState, onLogin }) => {
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        onLogin(values);
    };

    if (authState.isConnected) {
        return <Redirect to="/" />;
    }

    let error = authState.error ?
        'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
        null;

    if (authState.error && authState.error.status) {
        switch (authState.error.status) {
            case 401:
                error = 'Non autorisé';
                break;

            case 403:
                error = 'Mot de passe incorrect';
                break;

            case 404:
                error = 'Nom d\'utilisateur introuvable';
                break;

            default:
                error = 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur';
                break;
        }
    }

    return (
        <Layout id="login-layout">
            <Seo title="Login page" />
            <div id="login-section">
                <Form
                    className="login-form"
                    onFinish={onFormValidSubmit}
                    hideRequiredMark
                >
                    <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo/lyr-logo-light-bg@3x.png`} alt="logo" />

                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Veuillez renseigner votre e-mail' }]}
                    >
                        <Input
                            size="large"
                            placeholder="Nom d'utilisateur"
                        />
                    </Form.Item>

                    <Form.Item
                        className="password-row"
                        name="password"
                        rules={[{ required: true, message: 'Veuillez renseigner votre mot de passe' }]}
                    >
                        <Input.Password
                            size="large"
                            placeholder="Mot de passe"
                        />
                    </Form.Item>

                    <Form.Item>
                        {error ? (
                            <div className="login-error-message">
                                <Alert
                                    type="error"
                                    message={error}
                                    showIcon
                                />
                            </div>
                        ) : null}

                        <Button
                            type="primary"
                            size="large"
                            block
                            htmlType="submit"
                            loading={authState.loading}
                        >
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Breakpoint lg up className="login-background" />
        </Layout>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    authState: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        onLogin: login.trigger,
    },
)(Login);
