import Cookies from 'universal-cookie';

import { EzeeAction } from '../helpers/EzeeAction';
import { MainReducerState } from '../reducers';
import { removeCookiesOptions } from '../../helpers/cookies';

export const UniversalCookie = new Cookies();

export enum CookieNames {
    HasMadeChoice = 'ezee-has_made_choice',
    GTAG = 'ezee-gtag',
}

type StateCookieNames = 'hasMadeChoice' | 'gtag';

// move from boolean to number
if (UniversalCookie.get(CookieNames.HasMadeChoice) === 'true') {
    UniversalCookie.remove(CookieNames.HasMadeChoice, removeCookiesOptions);
}

// State

export interface CookiesState {
    hasMadeChoice: boolean | null;
    gtag: boolean | null;
}

// set initial value from cookies
const initialState: CookiesState = {
    hasMadeChoice: UniversalCookie.get(CookieNames.HasMadeChoice) === undefined ?
        null :
        !!parseInt(UniversalCookie.get(CookieNames.HasMadeChoice), 10),
    gtag: UniversalCookie.get(CookieNames.GTAG) === undefined ? null : !!parseInt(UniversalCookie.get(CookieNames.GTAG), 10),
};

// Actions/Reducers

export const cookies = new EzeeAction<CookiesState>('cookies', initialState, {
    acceptAll: (state) => ({
        ...state,
        hasMadeChoice: true,
        gtag: true,
    }),
    refuse: (state) => ({
        ...state,
        hasMadeChoice: true,
        gtag: false,
    }),
    set: (state, payload) => ({
        ...state,
        [payload.name]: payload.value,
    }),
    toggle: (state, payload) => ({
        ...state,
        [payload]: !state[payload as StateCookieNames],
    }),
});

// Reducer

export const cookiesReducer = cookies.reducer;

// Store helpers

export const getCookiesState = (state: MainReducerState) => state.cookies;
