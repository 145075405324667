
import { Card, Form, Input, Typography } from 'antd';
import React, { FC } from 'react';
import { UploadImage } from './UploadFile';

export interface BannerProps {
    url: string;
    name: string;
}

export const Banner: FC<BannerProps> = ({
   url, name,
}) => {

    return (
        <>
            <Typography.Title className="title" level={2}>Bannière Haut</Typography.Title>
            <Card className="landing-card">

                    <Form.Item
                        name={['banner', 'title']}
                        label="Titre *"
                        rules={[{ required: true, message: 'Veuillez renseigner un titre de bannière' }]}
                    >
                        <Input
                            placeholder="Saisir un titre"
                        />
                    </Form.Item>

                    <Form.Item
                        name={['banner', 'subTitle']}
                        label="Sous-Titre"
                    >
                        <Input.TextArea
                            style={{minHeight: '111px'}}
                            placeholder="Saisir un sous-titre"
                        />
                    </Form.Item>
                    <Form.Item label="Image de fond" name={['bannerImage']}>
                        <UploadImage
                            info={`Dimensions (Minimum/Conseillé) : 1440px x 600px (Ratio: 16:9)`}
                            name={name}
                            url={url}
                        />
                    </Form.Item>
            </Card>
        </>
        );
};
