// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/login-background/picture_login@3x.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#login-layout {\n  min-height: 100vh;\n  background-color: #4692BC;\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n}\n#login-layout .login-background {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center center;\n  width: 50%;\n}\n#login-layout #login-section {\n  display: flex;\n  flex-flow: column;\n  background-color: #FFF;\n  flex-grow: 1;\n}\n#login-layout #login-section form {\n  display: block;\n  margin: auto;\n  width: 80%;\n  max-width: 360px;\n}\n#login-layout #login-section .logo {\n  display: block;\n  height: auto;\n  max-width: 200px;\n  margin: 0 auto 2em;\n}\n#login-layout #login-section .login-error-message {\n  margin-bottom: 1em;\n}\n", ""]);
// Exports
module.exports = exports;
