import React, { FC, useEffect, useState } from 'react';

// ANTD
import { Divider, Typography, Badge, Row, Col, Button, Table, Modal, Input, Tooltip, Alert, Form, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { TableProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';

// API Types
import { Editor as EditorApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    list,
    update,
    create,
    remove,
    getEditorsCreateState,
    getEditorsListState,
    getEditorsUpdateState,
    getEditorsRemoveState,
    EditorsState,
} from '../../store/actions/editors';

// Helpers
import { usePrevious } from '../../hooks';

// Style
import '../../assets/styles/Editors.less';

export interface EditorsProps {
    listEditorsState: EditorsState['list'];
    createEditorState: EditorsState['create'];
    updateEditorState: EditorsState['update'];
    removeEditorState: EditorsState['remove'];
    loadEditors: typeof list.trigger;
    updateEditors: typeof update.trigger;
    createEditors: typeof create.trigger;
    removeEditors: typeof remove.trigger;
}

const Editors: FC<EditorsProps> = ({
    listEditorsState, createEditorState, updateEditorState, removeEditorState, loadEditors, updateEditors, createEditors, removeEditors,
}) => {

    // Error
    let error = createEditorState.error ?
        'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur' :
        null;

    if (createEditorState.error && createEditorState.error.status) {
        switch (createEditorState.error.status) {
            case 409:
                error = 'Vous ne pouvez pas ajouter deux éditeurs avec la même adresse e-mail';
                break;

            default:
                error = 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur';
                break;
        }
    }

    const fromLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const [idToUpdate, setIdToUpdate] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [inputNameValue, setInputNameValue] = useState<string>('');
    const [inputEmailValue, setInputEmailValue] = useState<string>('');
    const [isUpdateEditorModalVisible, setIsUpdateEditorModalVisible] = useState<boolean>(false);
    const [isCreateEditorModalVisible, setIsCreateEditorModalVisible] = useState<boolean>(false);

    const rowKey = (item: EditorApiType) => `${item.id}`;

    const previous = usePrevious({
        createEditorState,
        updateEditorState,
        removeEditorState,
    });

    const onSearch = (value: string) => {
        if (!value || !value.length) {
            loadEditors({});
            setSearchInputValue('');
        } else {
            loadEditors({
                search: value,
            });
            setSearchInputValue(value);
        }
    };

    const onChangeInputName = (e: any) => {
        setInputNameValue(e.target.value);
    };

    const onChangeInputEmail = (e: any) => {
        setInputEmailValue(e.target.value);
    };

    // Update Editor Setting
    const onClickUpdateButton = (id: EditorApiType['id']) => {
        const name = `${listEditorsState.data.items.find((e) => e.id === id)?.name}`;
        const email = `${listEditorsState.data.items.find((e) => e.id === id)?.email}`;
        setIsUpdateEditorModalVisible(true);
        setInputNameValue(name);
        setInputEmailValue(email);
        setIdToUpdate(id);
    };
    const onOkUpdateButton = () => {
        setIsUpdateEditorModalVisible(false);
        updateEditors({
            id: idToUpdate,
            body: {
                name: inputNameValue,
                email: inputEmailValue,
            },
        });
        setInputNameValue('');
        setInputEmailValue('');
        setIdToUpdate('');
    };
    const onCancelUpdateButton = () => {
        setIsUpdateEditorModalVisible(false);
        setInputNameValue('');
        setInputEmailValue('');
        setIdToUpdate('');
    };

    // Create Editor Setting
    const onClickCreateButton = () => {
        setIsCreateEditorModalVisible(true);
        setInputNameValue('');
        setInputEmailValue('');
        setIdToUpdate('');
    };
    const onOkCreateButton = () => {
        setIsCreateEditorModalVisible(false);
        createEditors({
            body: {
                name: inputNameValue,
                email: inputEmailValue,
            },
        });
        setInputNameValue('');
        setInputEmailValue('');
        setIdToUpdate('');
    };
    const onCancelCreateButton = () => {
        setIsCreateEditorModalVisible(false);
        setInputNameValue('');
        setInputEmailValue('');
        setIdToUpdate('');
    };

    // Delete Editor Setting
    const onClickRemoveButton = (id: EditorApiType['id']) => {
        removeEditors(id);
    };

    const onTableChange: TableProps<EditorApiType>['onChange'] = (pagination, filter, sorter: any) => {
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (!sortOrder || !sortOrder.length) {
            if (!searchInputValue) {
                loadEditors({});
            } else {
                loadEditors({
                    search: searchInputValue,
                });
            }
        } else {
            if (!searchInputValue) {
                loadEditors({
                    sort: sorter.field,
                    sortOrder,
                });
            } else {
                loadEditors({
                    sort: sorter.field,
                    sortOrder,
                    search: searchInputValue,
                });
            }
        }
    };

    const columns: Array<ColumnProps<EditorApiType>> = [
        {
            title: 'Nom',
            dataIndex: 'name',
            sorter: true,
        },
        { title: 'Adresse e-mail', dataIndex: 'email' },
        { title: 'Total épisodes', dataIndex: 'numberOfVideos' },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: '15%',
            render: (id: string, record: EditorApiType) => {
                const isRemoveButtonDisabled = !!record.numberOfVideos && record.numberOfVideos > 0;
                const removeButton = (
                    <Popconfirm
                        placement="topLeft"
                        title={'Êtes-vous sûr de supprimer cet éditeur ?'}
                        onConfirm={onClickRemoveButton.bind(null, id)}
                        okText="Oui"
                        cancelText="Non"
                        disabled={isRemoveButtonDisabled}
                    >
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            disabled={isRemoveButtonDisabled}
                        />
                    </Popconfirm>
                );
                return (
                    <div>
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickUpdateButton.bind(null, id)}
                        />
                        {isRemoveButtonDisabled ?
                            (
                                <Tooltip
                                    placement="top"
                                    title={`Vous ne pouvez pas supprimer l'éditeur car il possède des web-séries`}
                                >
                                    {removeButton}
                                </Tooltip>
                            ) :
                            removeButton
                        }
                     </div>
                );
            },
        },
    ];

    useEffect(() => {
        if ((previous?.createEditorState.loading && !createEditorState.loading) ||
            (previous?.updateEditorState.loading && !updateEditorState.loading) ||
            (previous?.removeEditorState.loading && !removeEditorState.loading)) {
            loadEditors();
        }
    }, [loadEditors, previous, createEditorState, updateEditorState, removeEditorState]);

    useEffect(() => {
        loadEditors();
    }, [loadEditors]);

    return (
        <>
            <Seo title="Liste des éditeurs" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={12}>
                        <Typography.Title level={1}>Editeur <Badge count={listEditorsState.data.totalCount}/></Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            type="primary"
                            onClick={onClickCreateButton}
                        >
                            Ajouter un éditeur
                        </Button>
                    </Col>

                </Row>
                <Search
                    size="large"
                    placeholder="Rechercher un nom ou un email"
                    prefix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>

            <Table<EditorApiType>
                className="editor-list"
                columns={columns}
                rowKey={rowKey}
                loading={listEditorsState.loading}
                dataSource={listEditorsState.data.items}
                pagination={false}
                onChange={onTableChange}
            />

            <Modal
                className="editor-modals"
                title="Modifier l'éditeur"
                centered
                visible={isUpdateEditorModalVisible}
                okText="Valider"
                cancelText="Annuler"
                onOk={onOkUpdateButton}
                onCancel={onCancelUpdateButton}
            >
                <Form {...fromLayout}>
                    <Form.Item label="Nom">
                        <Input onChange={onChangeInputName} value={inputNameValue} placeholder="Nom de l'éditeur" />
                    </Form.Item>
                </Form>
                <Form {...fromLayout}>
                    <Form.Item label="Adresse e-mail">
                        <Input onChange={onChangeInputEmail} value={inputEmailValue} placeholder="E-mail de l'éditeur" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                className="editor-modals"
                title="Ajouter un éditeur"
                centered
                visible={isCreateEditorModalVisible}
                okText="Créer"
                cancelText="Annuler"
                onOk={onOkCreateButton}
                onCancel={onCancelCreateButton}
            >
                <Form {...fromLayout}>
                    <Form.Item label="Nom">
                        <Input onChange={onChangeInputName} value={inputNameValue} placeholder="Nom de l'éditeur" />
                    </Form.Item>
                </Form>
                <Form {...fromLayout}>
                    <Form.Item label="Adresse e-mail">
                        <Input onChange={onChangeInputEmail} value={inputEmailValue} placeholder="E-mail de l'éditeur" />
                    </Form.Item>
                </Form>
            </Modal>

            {error ? (
                <div className="login-error-message">
                    <Alert
                        type="error"
                        message={error}
                        showIcon
                    />
                </div>
            ) : null}
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    listEditorsState: getEditorsListState(state),
    createEditorState: getEditorsCreateState(state),
    updateEditorState: getEditorsUpdateState(state),
    removeEditorState: getEditorsRemoveState(state),
});

export default connect(
    mapStateToProps,
    {
        loadEditors: list.trigger,
        updateEditors: update.trigger,
        createEditors: create.trigger,
        removeEditors: remove.trigger,
    },
)(Editors);
