import React, { FC } from 'react';
import { Skeleton } from 'antd';

import '../assets/styles/CardSkeleton.less';

const CardSkeleton: FC<{ rows?: number }> = ({ rows }) => (
    <div className="card-skeleton">
        <div className="card-skeleton-body">
            <Skeleton
                paragraph={{ rows }}
                active
                loading
                title
            />
        </div>
    </div>
);
export default CardSkeleton;
