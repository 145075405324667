import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    UserDetailsPayload,
    UserListPayload,
    details as detailsApiCall,
    list as listApiCall,
    exportUsers as exportUsersApiCall,
} from '../api/users';

// State

export interface UsersState {
    list: RequestState<ListResponse<User>>;
    details: RequestState<{ [id: string]: User }>;
    exportUsers: RequestState<string>;
}

const initialState: UsersState = {
    details: {
        data: {},
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    exportUsers: {
        data: '',
        loading: false,
    },
};

// Actions/Reducers

export const details = new EzeeAsyncAction<
    UsersState['details'],
    UserDetailsPayload,
    User
>('users/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const list = new EzeeAsyncAction<
    UsersState['list'],
    UserListPayload,
    ListResponse<User>
>('users/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const exportUsers = new EzeeAsyncAction<
    UsersState['exportUsers'],
    string
>('users/exportUsers', initialState.exportUsers, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.exportUsers,
    }),
});

// Reducer

export const usersReducer = combineReducers<UsersState>({
    details: details.reducer,
    list: list.reducer,
    exportUsers: exportUsers.reducer,
});

// Saga

export function* usersSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(exportUsers.type.trigger, exportFileSaga);
}

function* exportFileSaga() {
    try {
        const response = yield call(exportUsersApiCall);
        const blob = new Blob([response], { type: 'application/csv'});
        const url = window.URL.createObjectURL(blob);
        return yield put(exportUsers.success(url));
    } catch (error) {
        return yield put(exportUsers.failure(error));
    }
}

// Store helpers

export const getUsersState = (state: MainReducerState) => state.users;
export const getExportUsersState = (state: MainReducerState) => state.users.exportUsers;
