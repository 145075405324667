import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// ANTD
import { Typography, Divider, Row, Col, Button } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ButtonLink from '../../components/ButtonLink';

import Seo from '../../components/Seo';

// API Types
import { PurchaseItem as PurchaseItemApiType, Video as VideoApiType, Purchase as PurchaseApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { details, invoice, PurchasesState, getPurchasesState } from '../../store/actions/purchases';
import { getRoute, RoutePathName } from '../../routes';

// Helpers
import { frCurrencyFormat } from '../../helpers/currency';
import { usePrevious } from '../../hooks';
import { downloadFile } from '../../helpers';

// Style
import '../../assets/styles/PurchasesDetails.less';
import CardSkeleton from '../../components/CardSkeleton';

const rowKey = (item: PurchaseItemApiType) => {
    if (!item.video) {
        return 'none';
    }
    return `${item.video.id}`;
};

const columns: Array<ColumnProps<PurchaseItemApiType>> = [
    {
        title: 'Web-série',
        dataIndex: 'video',
        key: 'webSerie',
        render: (video: VideoApiType) => {
            if (!video) {
                return '';
            }
            return video.webSerie.name;
        },
        sorter: (a, b) => {
            if (a.video?.webSerie.name && b.video?.webSerie.name) {
                return a.video?.webSerie.name.localeCompare(b.video?.webSerie.name);
            }
            return 1;
        },
    },
    {
        title: 'Episode',
        dataIndex: 'video',
        key: 'episode',
        render: (video: VideoApiType) => {
            if (!video) {
                return '';
            }
            return video.title;
        },
        sorter: (a, b) => {
            if (a.video?.title && b.video?.title) {
                return a.video?.title.localeCompare(b.video?.title);
            }
            return 1;
        },
    },
    {
        title: 'Montant',
        dataIndex: 'price',
        render: (price: number) => {
            return frCurrencyFormat(price);
        },
        sorter: (a, b) => a.price - b.price,
    },
    {
        title: 'Nombre de vues',
        dataIndex: 'video',
        key: 'numberOfViews',
        render: (video: VideoApiType) => {
            if (!video) {
                return '';
            }
            return video.numberOfViews;
        },
        sorter: (a, b) => {
            if (a.video?.numberOfViews && b.video?.numberOfViews) {
                return a.video?.numberOfViews - b.video?.numberOfViews;
            }
            return 1;
        },
    },
];

export interface PurchasesDetailsProps {
    purchases: PurchasesState;
    loadPurchases: typeof details.trigger;
    getInvoice: typeof invoice.trigger;
}

const PurchasesDetails: FC<PurchasesDetailsProps> = ({
    purchases, loadPurchases, getInvoice,
}) => {

    const { id } = useParams<{ id: string }>();

    const purchase = purchases.details.data[id];

    let videos: PurchaseItemApiType[] = [];

    let userId = '';

    if (purchase) {
        if (purchase.videos && videos) {
            videos = videos.concat(purchase.videos);
        }
        if (purchase.webSeriesVideos && videos) {
            videos = videos.concat(purchase.webSeriesVideos);
        }

        if (purchase.user) {
            userId = purchase.user?.id;
        }
    }

    const [idToDonwload, setIdToDonwload] = useState<string>('');

    const previous = usePrevious(purchases.invoice);

    const onInvoiceDownload = (invoiceId: PurchaseApiType['id']) => {
        setIdToDonwload(invoiceId);
        getInvoice(invoiceId);
    };

    useEffect(() => {
        loadPurchases({ purchaseId: id });
    }, [loadPurchases, id]);

    useEffect(() => {
        if (!purchases.invoice.loading && previous?.loading && !purchases.invoice.error) {
            const filename = `Facture_${purchase.reference}​​​​​`;
            downloadFile(purchases.invoice.data, filename);
        }
    }, [purchases.invoice, purchase, previous, idToDonwload]);

    return (
        <>
            <Seo title="Détails de la commande" />
            {purchases.details.loading && (<CardSkeleton rows={4} />)}
            {!purchases.details.loading && purchase && (
            <div className="purchases-details-header">
                <Divider className="purchases-details-header-divider"/>
                <Row>
                    <Col flex="62px" className="return-button">
                        <ButtonLink
                            shape="circle"
                            to={getRoute(RoutePathName.userDetails, { id: userId })}
                        >
                            <ArrowLeftOutlined />
                        </ButtonLink>
                    </Col>
                    <Col flex="auto">
                        <Row>
                            <Col span={12}>
                                <Typography.Title level={1}>
                                    Detail de la commande
                                </Typography.Title>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={onInvoiceDownload.bind(null, id)}
                                    className="header-button"
                                    type="primary"
                                >
                                    Télécharger la facture
                                </Button>
                            </Col>
                        </Row>
                        <Row className="informations-row">
                            <Col span={8}>
                                <p className="properties">Date :</p>
                                <p>{moment(purchase.succeededAt).format('DD/MM/YYYY')}</p>
                            </Col>
                            <Col span={8}>
                                <p className="properties">Numéro de commande :</p>
                                <p>{purchase.reference}</p>
                            </Col>
                            <Col span={8}>
                                <p className="properties">N° de transaction Stripe :</p>
                                <p>{purchase.paymentIntent?.id}</p>
                            </Col>
                        </Row>
                        <Row className="informations-row">
                            <Col>
                                <p className="properties">Adresse de facturation :</p>
                                <p>{purchase.address.street + ' - '}
                                {purchase.address.zipCode + ' '}
                                {purchase.address.city + ' - '}
                                {purchase.address.country}</p>
                            </Col>
                        </Row>
                        <Row className="informations-row">
                            <Col span={8}>
                                <p className="properties">Articles :</p>
                                <p>{purchase.numberOfItems}</p>
                            </Col>
                            <Col span={8}>
                                <p className="properties">Montant :</p>
                                <p>{frCurrencyFormat(purchase.price)}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            )}

            <Table<PurchaseItemApiType>
                className="purchases-details-table"
                columns={columns}
                rowKey={rowKey}
                loading={purchases.details.loading}
                dataSource={videos}
                pagination={false}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    purchases: getPurchasesState(state),
});

export default connect(
    mapStateToProps,
    {
        loadPurchases: details.trigger,
        getInvoice: invoice.trigger,
    },
)(PurchasesDetails);
