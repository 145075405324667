import { SearchPaginationQuery } from './';
import { serialize } from 'object-to-formdata';
import api from './_client';

export type WebSeriesListPayload = WebSeriesSearchQuery & SearchPaginationQuery;

export interface WebSeriesSearchQuery {
    minimumPrice?: number;
}

export interface WebSerieDetailsPayload {
    id: string;
}

export interface WebSeriesUpdatePayload {
    id: string;
    body: {
        name?: string,
        price?: number,
        description?: string,
        editor?: string,
        category?: string,
        offline?: string[],
        online?: string[],
        preview: File;
    };
}

export const list = (payload: WebSeriesListPayload) => {
    return api.get(`/webseries`, { params: payload });
};

export const details = (payload: WebSerieDetailsPayload) => api.get(`/webseries/${payload.id}`);

export const update = (payload: WebSeriesUpdatePayload) => {
    const formData = serialize(payload.body, { indices: true });
    return api.put(`/webseries/${payload.id}`, formData);
};
