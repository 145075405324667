import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Video, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    SelectionListPayload,
    list as listApiCall,
} from '../api/videos';

export interface VideoState {
    list: RequestState<ListResponse<Video>>;
}

const initialState: VideoState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const list = new EzeeAsyncAction<
    VideoState['list'],
    SelectionListPayload,
    ListResponse<Video>
>('videos/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});
export const videosReducer = combineReducers<VideoState>({
    list: list.reducer,
});

export function* videosSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

export const getvideosState = (state: MainReducerState) => state.videos;
export const getlistVideosState = (state: MainReducerState) => state.videos.list;
