import React, { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { DashboardOutlined, FallOutlined, HomeOutlined, PlaySquareOutlined, StarOutlined, TeamOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { SiderProps } from 'antd/lib/layout';

import logo from '../assets/images/logo.png';
import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';
import SubMenu from 'antd/lib/menu/SubMenu';

const Sidebar: FC = () => {
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(LayoutContext);
    const location = useLocation();
    const onCollapse: SiderProps['onCollapse'] = (value) => {
        setIsSidebarCollapsed(value);
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            breakpoint="lg"
            id="sidebar"
        >
            <div id="logo-wrapper">
                <Link to={getRoute(RoutePathName.home)}>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                defaultOpenKeys={['sub1']}
            >
                <Menu.Item key={getRoute(RoutePathName.home)}>
                    <Link to={getRoute(RoutePathName.home)}>
                        <DashboardOutlined />
                        <span>Tableau de bord</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key={getRoute(RoutePathName.users)}>
                    <Link to={getRoute(RoutePathName.users)}>
                        <TeamOutlined />
                        <span>Clients</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key={getRoute(RoutePathName.editors)}>
                    <Link to={getRoute(RoutePathName.editors)}>
                        <VideoCameraOutlined />
                        <span>Editeurs</span>
                    </Link>
                </Menu.Item>

                <SubMenu icon={<PlaySquareOutlined />} title="Web-series">
                    <Menu.Item key={getRoute(RoutePathName.categories)}>
                        <Link to={getRoute(RoutePathName.categories)}>
                            <span>Catégories</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.webSeries)}>
                        <Link to={getRoute(RoutePathName.webSeries)}>
                            <span>Web-series</span>
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu icon={<StarOutlined />} title="Mise en avant">
                    <Menu.Item key={getRoute(RoutePathName.webSeriesSelections)}>
                        <Link to={getRoute(RoutePathName.webSeriesSelections)}>
                            <span>Web-series</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={getRoute(RoutePathName.videosSelections)}>
                        <Link to={getRoute(RoutePathName.videosSelections)}>
                            <span>Episodes</span>
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <Menu.Item key={getRoute(RoutePathName.landing)}>
                    <Link to={getRoute(RoutePathName.landing)}>
                        <HomeOutlined />
                        <span>Page d'Accueil</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key={getRoute(RoutePathName.promo)}>
                    <Link to={getRoute(RoutePathName.promo)}>
                        <FallOutlined />
                        <span>Codes Promo</span>
                    </Link>
                </Menu.Item>

            </Menu>
        </Layout.Sider>
    );
};

export default Sidebar;
