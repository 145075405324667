import React, { FC, useEffect } from 'react';
import moment from 'moment';

// ANTD
import { Typography, Badge, Divider, Row, Button, Col } from 'antd';
import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';

// API Types
import { User as UserApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { list, UsersState, getUsersState, exportUsers as exportUsersCall, getExportUsersState } from '../../store/actions/users';

// Helpers
import { shortCivility } from '../../helpers/frenchConversion';

// Style
import '../../assets/styles/ClientsList.less';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { usePrevious } from '../../hooks';
import { downloadFile } from '../../helpers';

const rowKey = (item: UserApiType) => `${item.id}`;

const columns: Array<ColumnProps<UserApiType>> = [
    {
        title: 'Civilité',
        dataIndex: 'gender',
        render: (gender: string) => {
            if (!gender) {
                return '-';
            }
            return shortCivility(gender);
        },
    },
    {
        title: 'Nom',
        dataIndex: 'lastName',
        render: (lastName: string, record: UserApiType) => {
            if (lastName && record.firstName) {
                return `${lastName} ${record.firstName}`;
            }
            return '-';
        },
        sorter: true,
    },
    {
        title: 'Adresse e-mail',
        dataIndex: 'email',
        sorter: true,
    },
    {
        title: 'Date de création',
        dataIndex: 'createdAt',
        render: (createdAt: string) => {
            if (!createdAt) {
                return '-';
            }
            return moment(createdAt).format('DD/MM/YYYY');
        },
        defaultSortOrder: 'descend',
        sorter: true,
    },
    {
        title: 'Transactions',
        dataIndex: 'numberOfTransactions',
        render: (numberOfTransactions: string) => {
            if (!numberOfTransactions) {
                return 0;
            }
            return numberOfTransactions;
        },
    },
    {
        title: 'Dernières transactions',
        dataIndex: 'lastTransactionDate',
        render: (lastTransactionDate: string) => {
            if (!lastTransactionDate) {
                return '-';
            }
            return moment(lastTransactionDate).format('DD/MM/YYYY');
        },
        sorter: true,
    },
    {
        title: 'Actions',
        dataIndex: 'id',
        render: (id: string) => {
            return (
                    <ButtonLink
                        shape="circle"
                        to={getRoute(RoutePathName.userDetails, { id })}
                    >
                        <EyeOutlined />
                    </ButtonLink>
            );
        },
    },
];

export interface ClientsListProps {
    users: UsersState;
    loadUsers: typeof list.trigger;
    exportUsersState: UsersState['exportUsers'];
    getExportUsers: typeof exportUsersCall.trigger;
}

const ClientsList: FC<ClientsListProps> = ({
    users, loadUsers, exportUsersState, getExportUsers,
}) => {
    const previous = usePrevious(exportUsersState);
    const onDowload = () => {
        getExportUsers();
    };
    useEffect(() => {
        loadUsers({
            sort: 'createdAt',
            sortOrder: 'desc',
        });
    }, [loadUsers]);

    useEffect(() => {
        if (!exportUsersState.loading && previous?.loading && !exportUsersState.error) {
            downloadFile(exportUsersState.data, `export_lyr_users_${moment().format('YYYYMMDD')}.csv`);
        }
    }, [previous, exportUsersState]);

    const onTableChange: TableProps<UserApiType>['onChange'] = (pagination, filters, sorter: any) => {
        let page = 0;
        if (pagination.current) {
            page = pagination.current - 1;
        }
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (!sortOrder || !sortOrder.length) {
            loadUsers({
                page,
                pageSize: pagination.pageSize,
            });
        } else {
            loadUsers({
                sort: sorter.field,
                sortOrder,
                page,
                pageSize: pagination.pageSize,
            });
        }
    };

    const onSearch = (value: string) => {
        if (!value || !value.length) {
            loadUsers({});
        } else {
            loadUsers({
                search: value,
            });
        }
    };

    const itemsPerPage = 20;

    return (
        <>
            <Seo title="Liste des clients" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={12}>
                        <Typography.Title level={1}>
                            Clients <Badge overflowCount={999} count={users.list.data.totalCount}/>
                        </Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            type="primary"
                            onClick={onDowload}
                            loading={exportUsersState.loading}
                        >
                            Exporter les données
                        </Button>
                    </Col>

                </Row>
                <Search
                    size="large"
                    placeholder="Rechercher un nom ou un email"
                    prefix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>

            <Table<UserApiType>
                className="clients-list-table"
                columns={columns}
                rowKey={rowKey}
                loading={users.list.loading}
                dataSource={users.list.data.items}
                pagination={{
                    total: users.list.data.totalCount,
                    pageSize: itemsPerPage,
                    hideOnSinglePage: true,
                }}
                onChange={onTableChange}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    users: getUsersState(state),
    exportUsersState: getExportUsersState(state),
});

export default connect(
    mapStateToProps,
    {
        loadUsers: list.trigger,
        getExportUsers: exportUsersCall.trigger,
    },
)(ClientsList);
