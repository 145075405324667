import React, { FC } from 'react';
import { Link, LinkProps, NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export type ButtonLinkProps = LinkProps & ButtonProps & {
    isNavLink?: boolean;
    onLinkClick?: LinkProps['onClick'];
};

const ButtonLink: FC<ButtonLinkProps> = ({
    children, download, href, isNavLink, onLinkClick, target, to, ...rest
}) =>
    href ? (
        <Button {...rest}>
            <a
                href={href}
                download={download}
                rel="noopener noreferrer"
                target={target}
            >
                {children}
            </a>
        </Button>
    ) : (
        isNavLink ? (
            <Button {...rest}>
                <NavLink to={to} tabIndex={-1} onClick={onLinkClick}>
                    {children}
                </NavLink>
            </Button >
        ) : (
            <Button {...rest}>
                <Link to={to} tabIndex={-1} onClick={onLinkClick}>
                    {children}
                </Link>
            </Button >
        )
    );

export default ButtonLink;
