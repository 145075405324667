
import { EzeeAction } from '../helpers/EzeeAction';
import { MainReducerState } from '../reducers';

// State

export interface TestState {
    foo: string;
    test?: string;
    bolosse: boolean;
}

const initialState: TestState = {
    foo: 'bar',
    bolosse: false,
};

// Actions/Reducers

export const test = new EzeeAction<TestState>('test', initialState, {
    yolo: (state, payload) => ({
        ...state,
        test: 'yolo',
        bolosse: !state.bolosse,
    }),
});

// Reducer

export const testReducer = test.reducer;

// Store helpers

export const getTestState = (state: MainReducerState) => state.test;
