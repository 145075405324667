
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Input, Switch, Typography } from 'antd';
import React, { FC } from 'react';
import { SwitchProps } from 'antd/lib/switch';
import { UploadImage } from './UploadFile';
import { FormInstance } from 'antd/lib/form';

export interface BlockProps {
    title: string;
    blockNumber: string;
    nameImage: string;
    check: boolean;
    setBlock1: (value: boolean) => void;
    form: FormInstance<any>;
}

export const Block: FC<BlockProps> = ({
   title, blockNumber, nameImage, check, setBlock1, form,
}) => {
    const onChangeBlock1: SwitchProps['onChange'] = (checked: boolean) => {
        setBlock1(checked);
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography.Title level={4}>{title}</Typography.Title>
                <Form.Item
                    label="Afficher"
                    name={['blocks', blockNumber, 'display']}
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        className="switch-style"
                        defaultChecked={false}
                        style={{
                            marginLeft: '12px',
                        }}
                        checked={check}
                        onChange={onChangeBlock1}
                    />
                </Form.Item>
            </div>

            <Form.Item
                name={['blocks', blockNumber, 'title']}
                label="Titre *"
                rules={[{ required: true, message: 'Veuillez renseigner un titre' }]}
            >
                <Input
                    placeholder="Saisir un titre"
                />
            </Form.Item>
            <Form.Item
                label="Image *"
                name={[nameImage]}
                rules={[{ required: true, message: 'Veuillez renseigner une image' }]}
            >
                <UploadImage
                    info={`Dimensions (Minimum/Conseillée) : 620px x 350px (Ratio: 16:9)`}
                    name={form.getFieldValue([nameImage, 'originalName'])}
                    url={form.getFieldValue([nameImage, 'url'])}
                />
            </Form.Item>
        </>
        );
};
