import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

// ANTD
import { Row, Col, Card, Typography, Table, Switch } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

// API Types
import { Editor as EditorApiType } from '../../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import { editorsStats, StatsState, getStatsState } from '../../../store/actions/stats';

// Helpers
import { frCurrencyFormat } from '../../../helpers/currency';

// Style
import '../../../assets/styles/EditorsStats.less';

const pageSize = 5;

const dateKey = moment().format('YYYY-MM');

const rowKey = (item: EditorApiType) => `${item.id}`;

const columns: Array<ColumnProps<EditorApiType>> = [
    { title: 'Editeur', dataIndex: 'name', sorter: true},
    { title: 'Nb de web-séries', dataIndex: 'numberOfWebSeries'},
    { title: 'Nb de vidéo', dataIndex: 'numberOfVideos'},
    {
        title: `Chiffre d'affaires`,
        dataIndex: 'stats',
        key: 'turnover',
        sorter: true,
        render: (stats: any) => {
            const key = `${dateKey}`;

            if (stats[key]) {
                return `${frCurrencyFormat(stats[key].turnover)}`;
            }
            return `${frCurrencyFormat(0)}`;
        },
    },
];

export interface EditorsStatsProps {
    stats: StatsState;
    loadStats: typeof editorsStats.trigger;
}

const EditorsStats: FC<EditorsStatsProps> = ({
    stats, loadStats,
}) => {
    const [onlyVimeo, setOnlyVimeo] = useState(true);
    const onTableChange: TableProps<EditorApiType>['onChange'] = (pagination, filter, sorter: any) => {
        let sort = '';
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (sorter.field === 'name') {
            sort = sorter.field;
        } else if (sorter.columnKey === 'turnover') {
            sort = `${sorter.field}.${dateKey}.${sorter.columnKey}`;
        }

        let page = 0;
        if (pagination.current) {
            page = pagination.current - 1;
        }

        if (!sortOrder || !sortOrder.length) {
            loadStats({
                isDeleted: onlyVimeo === false ? undefined : false,
                page,
                pageSize: pagination.pageSize,
            });
        } else {
            loadStats({
                isDeleted: onlyVimeo === false ? undefined : false,
                page,
                pageSize: pagination.pageSize,
                sort,
                sortOrder,
            });
        }
    };

    useEffect(() => {
        loadStats({isDeleted: onlyVimeo === false ? undefined : false});
    }, [loadStats, onlyVimeo]);

    const onVimeoChange = (checked: boolean) => {
        setOnlyVimeo(checked);
    };

    return (
        <>
            <Card className="editor-stats">
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <Typography.Title level={4} className="title">EDITEURS</Typography.Title>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[24, 0]}>
                            <Col><Typography.Title level={4} className="title">Seulement vimeo</Typography.Title></Col>
                            <Col><Switch defaultChecked onChange={onVimeoChange}/></Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Table
                            className="components-table-demo-nested"
                            columns={columns}
                            rowKey={rowKey}
                            loading={stats.editorsStats.loading}
                            dataSource={stats.editorsStats.data.items}
                            pagination={{
                                total: stats.editorsStats.data.totalCount,
                                current: stats.editorsStats.data.page + 1,
                                pageSize,
                                position: ['bottomCenter'],
                            }}
                            onChange={onTableChange}
                        />
                    </Col>
                </Row>
            </Card>
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    stats: getStatsState(state),
});

export default connect(
    mapStateToProps,
    {
        loadStats: editorsStats.trigger,
    },
)(EditorsStats);
