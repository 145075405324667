export default Object.freeze({
    PAGE_SIZE: 50,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}[\]()\\\-_/'"`~,;:.<>])(.{10,})$/,
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Back office boilerplate',
        DEFAULT_TITLE: 'Back office boilerplate',
        DEFAULT_DESCRIPTION: 'Boilerplate for all back offices developments based on reactjs, ant design, redux...',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
});
