import { SearchPaginationQuery } from './';
import api from './_client';
import { Category } from './apiTypes';

export type CategoriesListPayload = SearchPaginationQuery;

export type CategoryIdPayload = Category['id'];

export interface CategoryUpdatePayload {
    id: CategoryIdPayload;
    body: {
        name: Category['name'],
    };
}

export interface CategoryCreatePayload {
    body: {
        name: Category['name'],
    };
}

export const create = (payload: CategoryCreatePayload) => {
    return api.post(`/category`, payload.body);
};

export const list = (payload: CategoriesListPayload = {}) => {
    return api.get(`/categories`, { params: payload });
};

export const update = (payload: CategoryUpdatePayload) => {
    return api.put(`/category/${payload.id}`, payload.body);
};

export const remove = (payload: CategoryIdPayload) => api.delete(`/category/${payload}`);
