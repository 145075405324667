import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Editor, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    EditorsIdPayload,
    EditorsCreatePayload,
    EditorsListPayload,
    EditorsUpdatePayload,
    create as createApiCall,
    list as listApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/editors';

// State
export interface EditorsState {
    list: RequestState<ListResponse<Editor>>;
    update: RequestState<Editor>;
    create: RequestState<Editor>;
    remove: RequestState<Editor>;
}

const initialState: EditorsState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
            email: '',
        },
        loading: false,
    },
    create: {
        data: {
            id: '',
            name: '',
            email: '',
        },
        loading: false,
    },
    remove: {
        data: {
            id: '',
            name: '',
            email: '',
        },
        loading: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    EditorsState['list'],
    EditorsListPayload,
    ListResponse<Editor>
>('/editors/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const update = new EzeeAsyncAction<
    EditorsState['update'],
    EditorsUpdatePayload,
    Editor
>('editors/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const create = new EzeeAsyncAction<
    EditorsState['create'],
    EditorsCreatePayload,
    Editor
>('editors/create', initialState.create, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const remove = new EzeeAsyncAction<
    EditorsState['remove'],
    EditorsIdPayload,
    Editor
>('editors/remove', initialState.remove, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const editorsReducer = combineReducers<EditorsState>({
    list: list.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
});

// Saga

export function* editorsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
}

// Store helpers

export const getEditorsState = (state: MainReducerState) => state.editors;
export const getEditorsCreateState = (state: MainReducerState) => state.editors.create;
export const getEditorsListState = (state: MainReducerState) => state.editors.list;
export const getEditorsRemoveState = (state: MainReducerState) => state.editors.remove;
export const getEditorsUpdateState = (state: MainReducerState) => state.editors.update;
