import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { WebSerie, Video, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    SelectionListPayload,
    WebSeriesSelectionUpdatePayload,
    VideosSelectionUpdatePayload,
    webSeriesSelectionList as webSeriesSelectionListApiCall,
    webSeriesSelection as webSeriesSelectionApiCall,
    updateWebSeriesSelection as updateWebSeriesSelectionApiCall,
    videoSelectionList as videoSelectionListApiCall,
    videosSelection as videosSelectionApiCall,
    updateVideosSelection as updateVideosSelectionApiCall,
} from '../api/selections';

export interface SelectionsState {
    webSeriesSelectionsToList: RequestState<ListResponse<WebSerie>>;
    listWebSeriesSelections: RequestState<ListResponse<WebSerie>>;
    updateWebSeriesSelections: RequestState<WebSerie>;
    videoSelectionsToList: RequestState<ListResponse<Video>>;
    listVideosSelections: RequestState<ListResponse<Video>>;
    updateVideosSelections: RequestState<Video>;
}

const initialState: SelectionsState = {
    webSeriesSelectionsToList: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    listWebSeriesSelections: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    updateWebSeriesSelections: {
        data: {
            id: '',
            name: '',
            uri: '',
            numberOfVideos: 0,
        },
        loading: false,
    },
    videoSelectionsToList: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    listVideosSelections: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    updateVideosSelections: {
        data: {
            id: '',
            title: '',
            duration: 0,
            publishedAt: new Date(),
            isTrailer: false,
            webSerie: {
                id: '',
                name: '',
                uri: '',
                numberOfVideos: 0,
            },
        },
        loading: false,
    },
};

export const webSeriesSelectionsToList = new EzeeAsyncAction<
    SelectionsState['webSeriesSelectionsToList'],
    SelectionListPayload,
    ListResponse<WebSerie>
>('selections/webSeriesSelectionsToList', initialState.webSeriesSelectionsToList, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.webSeriesSelectionsToList,
    }),
});

export const listWebSeriesSelections = new EzeeAsyncAction<
    SelectionsState['listWebSeriesSelections'],
    SelectionListPayload,
    ListResponse<WebSerie>
>('selections/listWebSeries', initialState.listWebSeriesSelections, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.listWebSeriesSelections,
    }),
});

export const updateWebSeriesSelections = new EzeeAsyncAction<
    SelectionsState['updateWebSeriesSelections'],
    WebSeriesSelectionUpdatePayload,
    WebSerie
>('selections/updateWebSeries', initialState.updateWebSeriesSelections, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.updateWebSeriesSelections,
    }),
});

export const videoSelectionsToList = new EzeeAsyncAction<
    SelectionsState['videoSelectionsToList'],
    SelectionListPayload,
    ListResponse<Video>
>('selections/videoSelectionsToList', initialState.videoSelectionsToList, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.videoSelectionsToList,
    }),
});

export const listVideosSelections = new EzeeAsyncAction<
    SelectionsState['listVideosSelections'],
    SelectionListPayload,
    ListResponse<Video>
>('selections/listVideos', initialState.listVideosSelections, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.listVideosSelections,
    }),
});

export const updateVideosSelections = new EzeeAsyncAction<
    SelectionsState['updateVideosSelections'],
    VideosSelectionUpdatePayload,
    Video
>('selections/updateVideos', initialState.updateVideosSelections, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.updateVideosSelections,
    }),
});

export const selectionsReducer = combineReducers<SelectionsState>({
    webSeriesSelectionsToList: webSeriesSelectionsToList.reducer,
    listWebSeriesSelections: listWebSeriesSelections.reducer,
    updateWebSeriesSelections: updateWebSeriesSelections.reducer,
    videoSelectionsToList: videoSelectionsToList.reducer,
    listVideosSelections: listVideosSelections.reducer,
    updateVideosSelections: updateVideosSelections.reducer,
});

export function* selectionsSaga() {
    yield takeLatest(webSeriesSelectionsToList.type.trigger, simpleAsyncSaga(webSeriesSelectionListApiCall, webSeriesSelectionsToList));
    yield takeLatest(listWebSeriesSelections.type.trigger, simpleAsyncSaga(webSeriesSelectionApiCall, listWebSeriesSelections));
    yield takeLatest(updateWebSeriesSelections.type.trigger, simpleAsyncSaga(updateWebSeriesSelectionApiCall, updateWebSeriesSelections));
    yield takeLatest(videoSelectionsToList.type.trigger, simpleAsyncSaga(videoSelectionListApiCall, videoSelectionsToList));
    yield takeLatest(listVideosSelections.type.trigger, simpleAsyncSaga(videosSelectionApiCall, listVideosSelections));
    yield takeLatest(updateVideosSelections.type.trigger, simpleAsyncSaga(updateVideosSelectionApiCall, updateVideosSelections));
}

export const getSelectionsState = (state: MainReducerState) => state.selections;
export const getWebSeriesSelectionsToListState = (state: MainReducerState) => state.selections.webSeriesSelectionsToList;
export const getWebSeriesSelectionsState = (state: MainReducerState) => state.selections.listWebSeriesSelections;
export const getUpdateWebSeriesSelectionsState = (state: MainReducerState) => state.selections.updateWebSeriesSelections;
export const getVideosSelectionsToListState = (state: MainReducerState) => state.selections.videoSelectionsToList;
export const getVideosSelectionsState = (state: MainReducerState) => state.selections.listVideosSelections;
export const getUpdateVideosSelectionsState = (state: MainReducerState) => state.selections.updateVideosSelections;
