import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile, DraggerProps } from 'antd/lib/upload';
import React, { FC, useState, useEffect } from 'react';

export interface ImageProps {
    url?: string;
    name?: string;
    info?: string;
    setRemoveFile?: (value: string) => void;
    onChange?: (value: RcFile | undefined) => void;
}

export const UploadImage: FC<ImageProps> = ({
   url, name, info, onChange,
}) => {
    const [file, setFile] = useState<RcFile>();
    const [localFile, setLocalFile] = useState<string>();
    const [value, setValue] = useState<string | undefined>(url);
    const prepareLogoUpload: DraggerProps['beforeUpload'] = (newFile) => {
        setFile(newFile);
        onChange?.(newFile);
        setLocalFile(URL.createObjectURL(newFile));
        return false;
    };

    const onRemove = () => {
        setFile(undefined);
        setLocalFile(undefined);
        setValue(undefined);
        onChange?.(undefined);
    };

    useEffect(() => {
        setValue(url);
    }, [setValue, url]);
    if (value) {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', border: '1px solid #E0E0E0', padding: '16px' }}>
                    <div style={{ display: 'flex', color: '#4692BC'}}>
                        <img
                            style={{
                                flexShrink: 0,
                                objectFit: 'cover',
                                width: '128px',
                                height: '128px',
                            }}
                            src={value}
                            alt="new"
                        />
                        <div  style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '17px'}}>{name}</div>
                    </div>
                    <Button
                        style={{ marginTop: 'auto', marginBottom: 'auto', width: '40px', height: '40px', fontSize: '16px'}}
                        icon={<DeleteOutlined />}
                        shape="circle"
                        type="ghost"
                        size="small"
                        onClick={onRemove}
                    />
            </div>
        );
    } else if (file) {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', border: '1px solid #E0E0E0', padding: '16px'}}>
                <div style={{display: 'flex', color: '#4692BC'}}>
                    <img
                        style={{
                            flexShrink: 0,
                            objectFit: 'cover',
                            width: '128px',
                            height: '128px',
                        }}
                        src={localFile}
                        alt="new"
                    />
                    <div  style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: '17px'}}>{file.name}</div>
                </div>
                <Button
                    style={{ marginTop: 'auto', marginBottom: 'auto', width: '40px', height: '40px', fontSize: '16px' }}
                    icon={<DeleteOutlined />}
                    shape="circle"
                    type="ghost"
                    size="small"
                    onClick={onRemove}
                />
            </div>
        );
    } else {
        return (
            <Row className="dragger-row">
                <Col span={24}>
                    <Dragger
                        className="upload-box"
                        style={{background: '#F4F9FB'}}
                        name={'file'}
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={prepareLogoUpload}
                    >
                        <InboxOutlined className="ant-upload-drag-icon"/>
                        <p className="ant-upload-text">Cliquez ici ou déposez une image</p>
                        <p className="ant-upload-info">
                            {`Fichier au format jpg / png`}
                        </p>
                        <p className="ant-upload-hint">
                            {info}
                        </p>
                    </Dragger>
                </Col>
            </Row>
        );
    }
};
