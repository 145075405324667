import React, { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';

// ANTD
import { Button, Col, Drawer, Form, Input, Row } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import {  FormProps } from 'antd/lib/form';

// Api Type
import { Partner } from '../../store/api/apiTypes';

// Components
import { UploadImage } from './UploadFile';

export interface PartnerDrawerProps {
    indexToEdit?: number;
    items?: Partner[];
    isVisible: boolean;
    setItems: React.Dispatch<React.SetStateAction<Partner[] | undefined>>;
    onClose: DrawerProps['onClose'];
}

export const PartnerDrawer: FC<PartnerDrawerProps> = ({
    indexToEdit, isVisible, items, setItems, onClose,
}) => {
    const isEditing = indexToEdit !== undefined;
    const [formState] = Form.useForm();
    const [logo, setLogo] = useState<string>();
    const [nameImage, setNameImage] = useState<string>();

    const onClickUpdateButton = () => {
        formState.submit();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        const name = values.names;

        if (isEditing && items && indexToEdit !== undefined) {
            let logoFile = false;
            if (!values.url) {
                logoFile = true;
            }
            const itemToEdit = {...items[indexToEdit], name, ...values, logoFile };
            const newItems = [...items];
            newItems.splice(indexToEdit, 1, itemToEdit);
            setItems(newItems);
        } else if (items) {
            const newItems = {...values, logoFile: true, index: items?.length};
            setItems([...items, newItems]);
        } else {
            setItems({...values, logoFile: true});
        }

        if (onClose) {
            onClose({} as unknown as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
        }
    };

    useEffect(() => {
        if (!isVisible) {
            formState.resetFields();
        } else {
            if (indexToEdit !== undefined) {
                formState.setFieldsValue({
                    name: items?.[indexToEdit]?.name,
                    logo: items?.[indexToEdit]?.logo,
                });

                setLogo(
                    items?.[indexToEdit]?.logo.originalName ?
                    items?.[indexToEdit]?.logo.originalName
                    : items?.[indexToEdit]?.logoFile?.name,
                );
                setNameImage(
                    items?.[indexToEdit]?.logo.url ?
                    items?.[indexToEdit]?.logo.url
                    : URL.createObjectURL(items?.[indexToEdit]?.logo),
                );
            }

        }
    }, [ formState, isVisible, items, indexToEdit]);

    return (
        <>
            <Drawer
                className="partner-edition-drawer"
                title={isEditing ? 'Modifier le partenaire' : 'Ajouter un partenaire'}
                width={680}
                onClose={onClose}
                visible={isVisible}
                bodyStyle={{ paddingBottom: 80 }}
                destroyOnClose={true}
                footer={(
                    <Button onClick={onClickUpdateButton} type="primary" block size="large">
                        {isEditing ? 'Modifier' : 'Ajouter'}
                    </Button>
                )}
            >
                <Form
                    layout="vertical"
                    hideRequiredMark
                    form={formState}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nom *"
                                rules={[
                                    { required: true, message: 'Veuillez entrer le nom du partenaire' },
                                    {
                                        validator: async (_, value) => {
                                            const result = items?.filter((item, i) => i === indexToEdit ? false : item.name === value);
                                            if (result?.length === 0) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Nom déjà utilisé');
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder="Entrer le nom du partenaire" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="logo"
                        label="Logo *"
                        rules={[{ required: true, message: 'Veuillez mettre un logo' }]}
                    >
                        <UploadImage
                            info={`(Dimensions recommandées : 128x128px)`}
                            name={isEditing ? logo : undefined}
                            url={isEditing ? nameImage : undefined}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};
