import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import mainReducer from './reducers';
import mainSaga from './sagas';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares: any[] = [sagaMiddleware];

    const store = createStore(
        mainReducer,
        composeWithDevTools(applyMiddleware(...middlewares)),
    );

    sagaMiddleware.run(mainSaga);

    return store;
};

export default configureStore;
