import React, { FC, useEffect, useState } from 'react';

// ANTD
import { Button, Divider, message, Modal, Popconfirm, Select, Typography } from 'antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable, DragDropContextProps } from 'react-beautiful-dnd';

import Seo from '../../components/Seo';

// API Types
import { Video as VideoApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';

import {
    listVideosSelections,
    videoSelectionsToList,
    updateVideosSelections as updateVideosSelectionsApiCall,
    getVideosSelectionsState,
    getVideosSelectionsToListState,
    getUpdateVideosSelectionsState,
    SelectionsState,
} from '../../store/actions/selections';

// Helpers
import { usePrevious } from '../../hooks';

// Style
import '../../assets/styles/VideosSelections.less';

export interface SelectionsItem {
    id: string;
    title: string;
}

const reorder = (list: SelectionsItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export interface VideosSelectionsProps {
    listVideosSelectionsState: SelectionsState['listVideosSelections'];
    videosSelectionsToListState: SelectionsState['videoSelectionsToList'];
    updateVideosSelectionsState: SelectionsState['updateVideosSelections'];
    loadVideosSelections: typeof listVideosSelections.trigger;
    loadVideosSelectionsToList: typeof videoSelectionsToList.trigger;
    updateVideosSelections: typeof updateVideosSelectionsApiCall.trigger;
}

const VideosSelections: FC<VideosSelectionsProps> = ({
    listVideosSelectionsState,
    videosSelectionsToListState,
    updateVideosSelectionsState,
    loadVideosSelections,
    loadVideosSelectionsToList,
    updateVideosSelections,
}) => {
    const [items, setItems] = useState<SelectionsItem[]>([]);
    const [videoIdSelected, setVideoIdSelected] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [videoToAdd, setVideosToAdd] = useState<VideoApiType[]>([]);

    const previous = usePrevious({
        listVideosSelectionsState,
        videosSelectionsToListState,
        updateVideosSelectionsState,
    });

    const options = videoToAdd.map((video) => <Select.Option key={video.id} value={video.title}>{video.title}</Select.Option>);

    const onClickRemoveButton = (index: number) => {
        const newItems = items.slice(0, index).concat(items.slice(index + 1, items.length));
        setItems(newItems);
        const itemsToUpdate = [];
        for (let i = 0; i < newItems.length; i++) {
            const item = {
                id: newItems[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        updateVideosSelections({
            selections: itemsToUpdate,
        });
    };

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index,
        );
        setItems(newItems);

        const itemsToUpdate = [];
        for (let i = 0; i < newItems.length; i++) {
            const item = {
                id: newItems[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        updateVideosSelections({
            selections: itemsToUpdate,
        });
    };

    const onAddVideoButton = () => {
        setIsModalVisible(true);
        loadVideosSelectionsToList();
    };

    const onSelect = (value: any, element: any) => {
        setVideoIdSelected(element.key);
    };

    const onOkAddVideoButton = () => {
        setIsModalVisible(false);
        if (items.some((item) => item.id === videoIdSelected)) {
            message.warning('Cet épisode est déjà dans la selection');
            return;
        }
        const itemsToUpdate = [];
        let i = 0;
        for (i = 0; i < items.length; i++) {
            const item = {
                id: items[i].id,
                index: i,
            };
            itemsToUpdate.push(item);
        }
        const itemToAdd = {
            id: videoIdSelected,
            index: i === 0 ? 0 : i + 1,
        };
        itemsToUpdate.push(itemToAdd);
        updateVideosSelections({
            selections: itemsToUpdate,
        });
        setVideoIdSelected('');
    };

    const onCancelAddVideoButton = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (previous?.listVideosSelectionsState.loading && !listVideosSelectionsState.loading) {
            const newItems = [];
            for (const video of listVideosSelectionsState.data.items) {
                const newItem = {
                    id: video.id,
                    title: video.title,
                };
                newItems.push(newItem);
            }
            setItems(newItems);
        }

        if (previous?.updateVideosSelectionsState.loading && !updateVideosSelectionsState.loading) {
            loadVideosSelections({
                sort: 'selection.index',
                sortOrder: 'asc',
            });
        }
    }, [loadVideosSelections, previous, listVideosSelectionsState, updateVideosSelectionsState]);

    useEffect(() => {
        if (previous?.videosSelectionsToListState.loading && !videosSelectionsToListState.loading) {
            const videoArray = [];
            for (const video of videosSelectionsToListState.data.items) {
                if (video.isOnline) {
                    videoArray.push(video);
                }
            }
            setVideosToAdd(videoArray);
        }
    }, [previous, videosSelectionsToListState]);

    useEffect(() => {
        loadVideosSelections({
            sort: 'selection.index',
            sortOrder: 'asc',
        });
    }, [loadVideosSelections]);

    return (
        <>
            <Seo title="Sélections d'épisode" />
            <div className="page-header">
                <Divider />
                <Typography.Title level={1}>Carrousel des épisodes</Typography.Title>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        className="droppable-videos"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                    {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                            <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="draggable-videos"
                            >
                                <MenuOutlined className="menu-icon"/>
                                <p className="videos-name">{item.title}</p>
                                <Popconfirm
                                    placement="topLeft"
                                    title={'Êtes-vous sûr de supprimer cet épisode de la selection ?'}
                                    onConfirm={onClickRemoveButton.bind(null, index)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        className="delete-button"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                    />
                                </Popconfirm>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>

            <div className="videos-add-button-div">
                <Button
                    type="dashed"
                    size="large"
                    className="videos-add-button"
                    onClick={onAddVideoButton}
                    block
                >
                    Ajouter un épisode
                </Button>
            </div>

            <Modal
                className="videos-selection-modals"
                title="Ajouter un épisode"
                centered
                visible={isModalVisible}
                okText="Valider"
                cancelText="Annuler"
                onOk={onOkAddVideoButton}
                onCancel={onCancelAddVideoButton}
            >
                <Select
                    className="input-videos-selection"
                    showSearch
                    placeholder="Sélectionner un épisode"
                    defaultActiveFirstOption={false}
                    onSelect={onSelect}
                >
                    {options}
                </Select>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    listVideosSelectionsState: getVideosSelectionsState(state),
    videosSelectionsToListState: getVideosSelectionsToListState(state),
    updateVideosSelectionsState: getUpdateVideosSelectionsState(state),
});

export default connect(
    mapStateToProps,
    {
        loadVideosSelections: listVideosSelections.trigger,
        loadVideosSelectionsToList: videoSelectionsToList.trigger,
        updateVideosSelections: updateVideosSelectionsApiCall.trigger,
    },
)(VideosSelections);
