import { call, /* takeLatest, */ put } from 'redux-saga/effects';

import { DataAction } from './EzeeAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

export type CallableAsyncFunc<Payload> = (payload: Payload) => Promise<any>;

export function simpleAsyncSaga<Payload>(
    func: CallableAsyncFunc<Payload>,
    action: EzeeAsyncAction<any, Payload>,
) {
    return function* asyncSaga(actionData: DataAction<Payload>) {
        try {
            const response = yield call(func, actionData.payload);
            return yield put(action.success(response));
        } catch (error) {
            return yield put(action.failure(error));
        }
    };
}

// export function* takeLatestSimpleAsyncSaga<Payload>(
//     action: EzeeAsyncAction<any, Payload>,
//     func: CallableAsyncFunc<Payload>,
// ) {
//     yield takeLatest(action.type.start, simpleAsyncSaga(func, action));
// }
