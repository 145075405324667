
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { Landing } from '../../store/api/apiTypes';

import { RequestState } from '../../store/reducers';

export interface LandingValidationProps {
    uploadLandingState: RequestState<Landing | undefined>;
}

export const LandingValidation: FC<LandingValidationProps> = ({
    uploadLandingState,
}) => {

    return (
            <Card className="landing-card-validation">
                <Row >
                    <Col span={12}  style={{display: 'flex'}}>
                        <Typography.Title level={4}>Vous avez terminé vos modifications ?</Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            type="primary"
                            htmlType="submit"
                            loading={uploadLandingState.loading}
                        >
                            Oui, enregistrer
                        </Button>
                    </Col>

                </Row>
            </Card>
        );
};
