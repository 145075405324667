import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { MainStats, WebSerie, Editor, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    WebSeriesStatsPayload,
    EditorsStatsPayload,
    mainStats as mainStatsApiCall,
    webSeriesStats as webSeriesStatsApiCall,
    editorsStats as editorStatsApiCall,
    exportStats as exportStatsApiCall,
} from '../api/stats';

// State

export interface StatsState {
    mainStats: RequestState<MainStats>;
    webSeriesStats: RequestState<ListResponse<WebSerie>>;
    editorsStats: RequestState<ListResponse<Editor>>;
    exportStats: RequestState<string>;
}

const initialState: StatsState = {
    mainStats: {
        data: {
            turnover: 0,
            turnoverOfMonth: 0,
            cartAverageOfMonth: 0,
            numberOfInvoice: 0,
            numberOfUserCreated: 0,
            numberOfUserDeleted: 0,
            numberOfViewsOfMonth: 0,
        },
        loading: false,
    },
    webSeriesStats: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    editorsStats: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    exportStats: {
        data: '',
        loading: false,
    },
};

// Actions/Reducers

export const mainStats = new EzeeAsyncAction<
    StatsState['mainStats'],
    MainStats
>('stats/mainStats', initialState.mainStats, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.mainStats,
    }),
});

export const webSeriesStats = new EzeeAsyncAction<
    StatsState['webSeriesStats'],
    WebSeriesStatsPayload,
    ListResponse<WebSerie>
>('stats/webSeriesStats', initialState.webSeriesStats, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.webSeriesStats,
    }),
});

export const editorsStats = new EzeeAsyncAction<
    StatsState['editorsStats'],
    EditorsStatsPayload,
    ListResponse<Editor>
>('stats/editorStats', initialState.editorsStats, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.editorsStats,
    }),
});

export const exportStats = new EzeeAsyncAction<
    StatsState['exportStats'],
    string
>('stats/exportStats', initialState.exportStats, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.exportStats,
    }),
});

// Reducer

export const statsReducer = combineReducers<StatsState>({
    mainStats: mainStats.reducer,
    webSeriesStats: webSeriesStats.reducer,
    editorsStats: editorsStats.reducer,
    exportStats: exportStats.reducer,
});

// Saga

export function* statsSaga() {
    yield takeLatest(mainStats.type.trigger, simpleAsyncSaga(mainStatsApiCall, mainStats));
    yield takeLatest(webSeriesStats.type.trigger, simpleAsyncSaga(webSeriesStatsApiCall, webSeriesStats));
    yield takeLatest(editorsStats.type.trigger, simpleAsyncSaga(editorStatsApiCall, editorsStats));
    yield takeLatest(exportStats.type.trigger, exportFileSaga);
}

function* exportFileSaga() {
    try {
        const response = yield call(exportStatsApiCall);
        const blob = new Blob([response], { type: 'application/csv'});
        const url = window.URL.createObjectURL(blob);
        return yield put(exportStats.success(url));
    } catch (error) {
        return yield put(exportStats.failure(error));
    }
}

// Store helpers

export const getStatsState = (state: MainReducerState) => state.stats;
export const getExportStatsState = (state: MainReducerState) => state.stats.exportStats;
