import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { WebSerie, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    WebSeriesListPayload,
    WebSerieDetailsPayload,
    WebSeriesUpdatePayload,
    list as listApiCall,
    details as detailsApiCall,
    update as updateApiCall,
} from '../api/webseries';

export interface WebSerieState {
    list: RequestState<ListResponse<WebSerie>>;
    details: RequestState<WebSerie>;
    update: RequestState<WebSerie>;
}

const initialState: WebSerieState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    details: {
        data: {
            id: '',
            name: '',
            uri: '',
            numberOfVideos: 0,
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
            uri: '',
            numberOfVideos: 0,
        },
        loading: false,
    },
};

export const list = new EzeeAsyncAction<
    WebSerieState['list'],
    WebSeriesListPayload,
    ListResponse<WebSerie>
>('webSeries/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    WebSerieState['details'],
    WebSerieDetailsPayload,
    WebSerie
>('webSeries/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const update = new EzeeAsyncAction<
    WebSerieState['update'],
    WebSeriesUpdatePayload,
    WebSerie
>('webSeries/update', initialState.update, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const webSerieReducer = combineReducers<WebSerieState>({
    list: list.reducer,
    details: details.reducer,
    update: update.reducer,
});

export function* webSerieSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

export const getWebSerieState = (state: MainReducerState) => state.webSerie;
export const getListWebSeriesState = (state: MainReducerState) => state.webSerie.list;
export const getDetailsWebSeriesState = (state: MainReducerState) => state.webSerie.details;
export const getUpdateWebSeriesState = (state: MainReducerState) => state.webSerie.update;
