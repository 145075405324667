
import { Card, Divider, Form, Input, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FC } from 'react';
import { HeadlineBlock } from './HeadlineBlock';

export interface HeadLineProps {
    form: FormInstance<any>;
}

export const HeadLine: FC<HeadLineProps> = ({
    form,
}) => {

    return (
        <>
            <Typography.Title className="title" level={2}>À la une</Typography.Title>
            <Card className="landing-card">
                <Form.Item
                        name={['headline', 'subTitle']}
                        label="Sous-titre *"
                        rules={[{ required: true, message: 'Veuillez renseigner un sous-titre' }]}
                >
                        <Input.TextArea
                            placeholder="Saisir un titre"
                        />
                </Form.Item>
                <Divider/>
                <HeadlineBlock
                    title={'Bloc n° 1'}
                    numberBlock={'block1'}
                    nameImage={'headlineImage1'}
                    form={form}
                />
                <Divider/>
                <HeadlineBlock
                    title={'Bloc n° 2'}
                    numberBlock={'block2'}
                    nameImage={'headlineImage2'}
                    form={form}
                />
                <Divider/>
                <HeadlineBlock
                    title={'Bloc n° 3'}
                    numberBlock={'block3'}
                    nameImage={'headlineImage3'}
                    form={form}
                />

            </Card>
        </>
    );
};
