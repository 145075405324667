import { SearchPaginationQuery } from './';
import api from './_client';
import { User, Purchase } from './apiTypes';

export interface PurchaseDetailsPayload {
    purchaseId: string;
}

export type PurchaseIdPayload = Purchase['id'];

export type PurchaseListPayload = SearchPaginationQuery & { userId: User['id'] };

export const details = (payload: PurchaseDetailsPayload) => api.get(`/purchases/${payload.purchaseId}`);

export const invoice = (payload: PurchaseIdPayload) => api.get(`/purchases/invoice/${payload}`, { responseType: 'blob' });

export const list = (payload: PurchaseListPayload) => {
    return api.get(`/purchases`, { params: payload });
};
