import React, {FC, useEffect, useState} from 'react';

// ANTD
import { Badge, Button, Card, Col, Divider, Drawer, Form, Input, Row, Select, Switch, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    details as detailsWebSerie,
    update,
    WebSerieState,
    getDetailsWebSeriesState,
} from '../../store/actions/webseries';
import { list as listEditors, EditorsState, getEditorsListState } from '../../store/actions/editors';
import { list as listCategories, CategoriesState, getCategoriesListState } from '../../store/actions/categories';

// Api Type
import { Editor as EditorApiType, Category as CategoryApiType, Video as VideoApiType } from '../../store/api/apiTypes';

// Helpers
import { usePrevious } from '../../hooks';

// Style
import '../../assets/styles/DetailsWebSerie.less';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { FormProps } from 'antd/lib/form';
import ReactQuill from 'react-quill';

export interface DetailsWebSerieProps {
    id: string;
    setId: () => void;
    details: WebSerieState['details'];
    listOfEditors: EditorsState['list'];
    listOfCategories: CategoriesState['list'];
    getWebSerie: typeof detailsWebSerie.trigger;
    updateWebSerie: typeof update.trigger;
    loadEditors: typeof listEditors.trigger;
    loadCategories: typeof listCategories.trigger;
}

const DetailsWebSerie: FC<DetailsWebSerieProps> = ({
    id,
    setId,
    details,
    listOfEditors,
    listOfCategories,
    getWebSerie,
    updateWebSerie,
    loadEditors,
    loadCategories,
}) => {

    const [isVisibleDrawer, setIsVisibleForm] = useState<boolean>(false);

    const [editorList, setEditorList] = useState<EditorApiType[]>([]);
    const [categoriesList, setCategoriesList] = useState<CategoryApiType[]>([]);
    const [videoList, setVideoList] = useState<any[]>([]);
    const [coverFile, setCoverFile] = useState<RcFile | undefined>(undefined);
    const [coverUrl, setCoverUrl] = useState<string | undefined>(details.data?.largeThumbnail);
    const [description, setDescription] = useState(details.data.description);

    const optionsEditor = editorList.map((editor) => <Select.Option key={editor.id} value={editor.id}>{editor.name}</Select.Option>);
    const optionsCategory = categoriesList.map((category) => {
        return (<Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>);
    });

    const [formState] = Form.useForm();

    const previous = usePrevious({
        details,
        listOfEditors,
        listOfCategories,
    });

    const onClose = () => {
        setIsVisibleForm(false);
        setId();
        setCoverFile(undefined);
    };

    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            message.error('Veuillez insérer une image de type JPEG ou PNG');
            return false;
        }

        if (file.size / 1024 > 250) {
            message.error('Votre image est trop grande, veuillez insérer une image de taille 250ko');
            return false;
        }

        setCoverFile(file);
        setCoverUrl(URL.createObjectURL(file));

        return false;
    };

    const deselectAll = () => {
        for (const video of videoList) {
            formState.setFields([{
                name: `${video.id}`,
                value: false,
            }]);
        }
    };

    const selectAll = () => {
        for (const video of videoList) {
            formState.setFields([{
                name: `${video.id}`,
                value: true,
            }]);
        }
    };

    const onClickUpdateButton = () => {
        formState.submit();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        console.log();
        const offline = [];
        const online = [];
        for (const video of videoList) {
            if (values[video.id]) {
                online.push(video.id);
            } else {
                offline.push(video.id);
            }
        }
        updateWebSerie({
            id,
            body: {
                index: values.index,
                name: values.name,
                price: values.price,
                description,
                editor: values.editor,
                category: values.category,
                offline,
                online,
                preview: coverFile,
            },
        });
        setCoverFile(undefined);
        setIsVisibleForm(false);
    };

    useEffect(() => {
        if (previous?.details.loading && !details.loading) {

            // Set initial value for webSerie
            setIsVisibleForm(true);
            const initialValue: any = {
                index: details.data.index,
                name: details.data.name,
                price: details.data.price,
                description: details.data.description,
                editor: details.data.editor?.id,
                category: details.data.category?.id,
            };

            // Set initial value for description
            setDescription(details.data.description);

            // Set initial value for episode of webserie
            const videos = [];
            for (const video of details.data.videos ?? []) {
                const videoData = video as VideoApiType;
                initialValue[videoData.id] = videoData.isOnline;
                videos.push(videoData);
            }
            setVideoList(videos);
            setCoverUrl(details.data.largeThumbnail);
            // Set initial value for cover file
            setCoverFile(undefined);
            formState.setFieldsValue(initialValue);
        }
        if (previous?.listOfEditors.loading && !listOfEditors.loading) {
            setEditorList(listOfEditors.data.items);
        }
        if (previous?.listOfCategories.loading && !listOfCategories.loading) {
            setCategoriesList(listOfCategories.data.items);
        }
    }, [formState, previous, details, listOfEditors, listOfCategories, setEditorList, setCategoriesList]);

    useEffect(() => {
        if (id) {
            getWebSerie({
                id,
            });
            loadEditors();
            loadCategories();
        }
    }, [id, getWebSerie, loadEditors, loadCategories]);

    const quillModules = {
        toolbar : [
            ['link'],
        ],
    };

    const handleChange = (value: string) => {
        const isEmpty = /^<p><br><\/p>\n?$/.test(value);
        if (isEmpty) {
            setDescription('');
        } else {
            setDescription(value);
        }
    };

    return (
        <>
            <Drawer
                className="webserie-edition-drawer"
                title="Editer la web-serie"
                width={680}
                onClose={onClose}
                visible={isVisibleDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                destroyOnClose={true}
                footer={(
                    <Button onClick={onClickUpdateButton} type="primary" block size="large">
                        Modifier la web-serie
                    </Button>
                )}
            >
                <Form
                    layout="vertical"
                    hideRequiredMark
                    form={formState}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="index"
                                label="Position"
                                // rules={[{ required: true, message: 'Veuillez entrer la position de la web-série' }]}
                            >
                                <Input placeholder="Entrer la position de la web-série" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Titre"
                                rules={[{ required: true, message: 'Veuillez entrer le nom de la web-série' }]}
                            >
                                <Input placeholder="Entrer le nom de la web-série" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="price"
                                label="Prix"
                                rules={[{ required: true, message: 'Veuillez entrer le prix de la web-série' }]}
                            >
                                <Input placeholder="Entrer le prix de la web-série" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                            >
                                <ReactQuill
                                        modules={quillModules}
                                        placeholder={'Ajouter une description'}
                                        value={description}
                                        formats={['link']}
                                        onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="dragger-row">
                        <Col span={24}>
                            <p className="dragger-name">Cover</p>
                            <Dragger
                                className="upload-box"
                                name={'file'}
                                multiple={false}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >
                                <InboxOutlined className="ant-upload-drag-icon"/>
                                <p className="ant-upload-text">Cliquez ici ou déposez une image</p>
                                <p className="ant-upload-hint">
                                    Fichier au format jpg / png
                                </p>
                            </Dragger>
                            <img src={coverUrl} className="dragger-cover-preview" alt="cover" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="editor"
                                label="Editeur"
                            >
                                <Select
                                    className="input-editor-webserie"
                                    showSearch
                                    placeholder="Sélectionner un éditeur"
                                    defaultActiveFirstOption={false}
                                >
                                    {optionsEditor}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="Categorie"
                            >
                                <Select
                                    className="input-category-webserie"
                                    showSearch
                                    placeholder="Sélectionner une categorie"
                                    defaultActiveFirstOption={false}
                                >
                                    {optionsCategory}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className="divider-webserie-videos"/>
                    <div className="episode-card-header">
                        <span className="episode-card-title">Vidéos  <Badge count={videoList.length}/></span>
                        <p className="switch-buttons">
                            <Button onClick={selectAll} type="link">Tout activer</Button>
                            {` / `}
                            <Button onClick={deselectAll} type="link">Tout désactiver</Button>
                        </p>
                    </div>
                    <Card
                        className="episode-list"
                    >
                        {videoList.map((video, index) => {
                            const item = (
                                <div key={`big-div-${index}`}>
                                    <Row
                                        key={`row-${index}`}
                                    >
                                        <Col key={`col1-${index}`} span={12}>
                                            <div key={`div-${index}`}>
                                                <p key={`p-${index}`} className="webserie-edit-label">
                                                    {video.title}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col key={`col2-${index}`} span={12}>
                                            <Form.Item
                                                key={`form-item-${index}`}
                                                name={video.id}
                                                initialValue={video.isOnline}
                                                valuePropName="checked"
                                                noStyle={true}
                                            >
                                                <Switch key={`switch-${index}`} className="webserie-edit-switch" />
                                            </Form.Item>
                                            </Col>
                                    </Row>
                                    {index !== videoList.length - 1 ?
                                        (<Divider key={`divider-${index}`} className="divider-videos"/>) :
                                        undefined}
                                </div>
                            );
                            return item;
                        })}
                    </Card>
                </Form>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    details: getDetailsWebSeriesState(state),
    listOfEditors: getEditorsListState(state),
    listOfCategories: getCategoriesListState(state),
});

export default connect(
    mapStateToProps,
    {
        getWebSerie: detailsWebSerie.trigger,
        updateWebSerie: update.trigger,
        loadEditors: listEditors.trigger,
        loadCategories: listCategories.trigger,
    },
)(DetailsWebSerie);
