import React, { createContext, useState, FC } from 'react';

interface LayoutContextTypes {
    isSidebarCollapsed: boolean;
    setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextTypes>({
    isSidebarCollapsed: false,
    setIsSidebarCollapsed: () => undefined,
});

export const LayoutContextProvider: FC = ({ children }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const layoutContext = {
        isSidebarCollapsed,
        setIsSidebarCollapsed,
    };

    return (
        <LayoutContext.Provider value={layoutContext}>
            {children}
        </LayoutContext.Provider>
    );
};
