import React, { FC, useEffect, useState } from 'react';

// ANTD
import { Divider, Typography, Badge, Row, Col, Button, Table, Modal, Input, Tooltip, Form, Alert, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { TableProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';

// API Types
import { Category as CategoryApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    list,
    update,
    create,
    remove,
    CategoriesState,
    getCategoriesCreateState,
    getCategoriesListState,
    getCategoriesRemoveState,
    getCategoriesUpdateState,
} from '../../store/actions/categories';

// Helpers
import { usePrevious } from '../../hooks';

// Style
import '../../assets/styles/Categories.less';

export interface CategoryProps {
    listCategoriesState: CategoriesState['list'];
    createCategoriesState: CategoriesState['create'];
    updateCategoriesState: CategoriesState['update'];
    removeCategoriesState: CategoriesState['remove'];
    loadCategories: typeof list.trigger;
    updateCategories: typeof update.trigger;
    createCategories: typeof create.trigger;
    removeCategories: typeof remove.trigger;
}

const Categories: FC<CategoryProps> = ({
    listCategoriesState,
    createCategoriesState,
    updateCategoriesState,
    removeCategoriesState,
    loadCategories, updateCategories,
    createCategories,
    removeCategories,
}) => {

    // Error
    let error = null;

    if (createCategoriesState.error && createCategoriesState.error.status) {
        switch (createCategoriesState.error.status) {
            case 409:
                error = 'Vous ne pouvez pas ajouter deux catégories avec le même nom';
                break;

            default:
                error = 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur';
                break;
        }
    }

    const fromLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const [inputValue, setInputValue] = useState<string>('');
    const [idToUpdate, setIdToUpdate] = useState<string>('');
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [isUpdateCategoryModalVisible, setIsUpdateCategoryModalVisible] = useState<boolean>(false);
    const [isCreateCategoryModalVisible, setIsCreateCategoryModalVisible] = useState<boolean>(false);

    const rowKey = (item: CategoryApiType) => `${item.id}`;

    const previous = usePrevious({
        createCategoriesState,
        updateCategoriesState,
        removeCategoriesState,
    });

    const onSearch = (value: string) => {
        if (!value || !value.length) {
            loadCategories({});
            setSearchInputValue('');
        } else {
            loadCategories({
                search: value,
            });
            setSearchInputValue(value);
        }
    };

    const onChangeInput = (e: any) => {
        setInputValue(e.target.value);
    };

    // Update Category Setting
    const onClickUpdateButton = (id: CategoryApiType['id']) => {
        const name = `${listCategoriesState.data.items.find((e) => e.id === id)?.name}`;
        setIsUpdateCategoryModalVisible(true);
        setInputValue(name);
        setIdToUpdate(id);
    };
    const onOkUpdateButton = () => {
        setIsUpdateCategoryModalVisible(false);
        updateCategories({
            id: idToUpdate,
            body: {
                name: inputValue,
            },
        });
        setInputValue('');
        setIdToUpdate('');
    };
    const onCancelUpdateButton = () => {
        setIsUpdateCategoryModalVisible(false);
        setInputValue('');
        setIdToUpdate('');
    };

    // Create Category Setting
    const onClickCreateButton = () => {
        setIsCreateCategoryModalVisible(true);
        setInputValue('');
        setIdToUpdate('');
    };
    const onOkCreateButton = () => {
        setIsCreateCategoryModalVisible(false);
        createCategories({
            body: {
                name: inputValue,
            },
        });
        setInputValue('');
        setIdToUpdate('');
    };
    const onCancelCreateButton = () => {
        setIsCreateCategoryModalVisible(false);
        setInputValue('');
        setIdToUpdate('');
    };

    // Delete Category Setting
    const onClickRemoveButton = (id: CategoryApiType['id']) => {
        removeCategories(id);
    };

    const onTableChange: TableProps<CategoryApiType>['onChange'] = (pagination, filter, sorter: any) => {
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (!sortOrder || !sortOrder.length) {
            if (!searchInputValue) {
                loadCategories({});
            } else {
                loadCategories({
                    search: searchInputValue,
                });
            }
        } else {
            if (!searchInputValue) {
                loadCategories({
                    sort: sorter.field,
                    sortOrder,
                });
            } else {
                loadCategories({
                    sort: sorter.field,
                    sortOrder,
                    search: searchInputValue,
                });
            }
        }
    };

    const columns: Array<ColumnProps<CategoryApiType>> = [
        {
            title: 'Nom',
            dataIndex: 'name',
            sorter: true,
        },
        { title: 'Total épisodes', dataIndex: 'numberOfVideos' },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: '10%',
            render: (id: string, record: CategoryApiType) => {
                const isRemoveButtonDisabled = !!record.numberOfVideos && record.numberOfVideos > 0;
                const removeButton = (
                    <Popconfirm
                        placement="topLeft"
                        title={'Êtes-vous sûr de supprimer cette catégorie ?'}
                        onConfirm={onClickRemoveButton.bind(null, id)}
                        okText="Oui"
                        cancelText="Non"
                        disabled={isRemoveButtonDisabled}
                    >
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            disabled={isRemoveButtonDisabled}
                        />
                    </Popconfirm>
                );
                return (
                    <div>
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={onClickUpdateButton.bind(null, id)}
                            />
                        {isRemoveButtonDisabled ?
                            (
                                <Tooltip
                                    placement="top"
                                    title={`Vous ne pouvez pas supprimer la catégorie car il possède des web-séries`}
                                >
                                    {removeButton}
                                </Tooltip>
                            ) :
                            removeButton
                        }
                     </div>
                );
            },
        },
    ];

    useEffect(() => {
        if ((previous?.createCategoriesState.loading && !createCategoriesState.loading) ||
            (previous?.removeCategoriesState.loading && !removeCategoriesState.loading) ||
            (previous?.updateCategoriesState.loading && !updateCategoriesState.loading)) {
                loadCategories();
        }
    }, [loadCategories, previous, createCategoriesState, removeCategoriesState, updateCategoriesState]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    return (
        <>
            <Seo title="Liste des catégories" />
            <div className="page-header">
                <Divider />
                <Row>
                    <Col span={12}>
                        <Typography.Title level={1}>Catégories <Badge count={listCategoriesState.data.totalCount}/></Typography.Title>
                    </Col>
                    <Col span={12}>
                        <Button className="header-button" type="primary" onClick={onClickCreateButton}>Ajouter une catégorie</Button>
                    </Col>

                </Row>
                <Search
                    size="large"
                    placeholder="Rechercher une catégorie"
                    prefix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>

            <Table<CategoryApiType>
                className="categories-list"
                columns={columns}
                rowKey={rowKey}
                loading={listCategoriesState.loading}
                dataSource={listCategoriesState.data.items}
                pagination={false}
                onChange={onTableChange}
            />

            <Modal
                className="categories-modals"
                title="Modifier la catégorie"
                centered
                visible={isUpdateCategoryModalVisible}
                okText="Modifier"
                cancelText="Annuler"
                onOk={onOkUpdateButton}
                onCancel={onCancelUpdateButton}
            >
                <Form {...fromLayout}>
                    <Form.Item label="Nom">
                        <Input onChange={onChangeInput} value={inputValue} placeholder="Nom de la catégorie" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                className="categories-modals"
                title="Ajouter une catégorie"
                centered
                visible={isCreateCategoryModalVisible}
                okText="Ajouter"
                cancelText="Annuler"
                onOk={onOkCreateButton}
                onCancel={onCancelCreateButton}
            >
                <Form {...fromLayout}>
                    <Form.Item label="Nom">
                        <Input onChange={onChangeInput} value={inputValue} placeholder="Nom de la catégorie" />
                    </Form.Item>
                </Form>
            </Modal>

            {error ? (
                <div className="login-error-message">
                    <Alert
                        type="error"
                        message={error}
                        showIcon
                    />
                </div>
            ) : null}
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    listCategoriesState: getCategoriesListState(state),
    createCategoriesState: getCategoriesCreateState(state),
    updateCategoriesState: getCategoriesUpdateState(state),
    removeCategoriesState: getCategoriesRemoveState(state),
});

export default connect(
    mapStateToProps,
    {
        loadCategories: list.trigger,
        updateCategories: update.trigger,
        createCategories: create.trigger,
        removeCategories: remove.trigger,
    },
)(Categories);
