import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// ANTD
import { Typography, Divider, Row, Col, Button } from 'antd';
import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import { ArrowLeftOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import ButtonLink from '../../components/ButtonLink';

import Seo from '../../components/Seo';

// API Types
import { Purchase as PurchaseApiType } from '../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { list, invoice, PurchasesState, getPurchasesState } from '../../store/actions/purchases';
import { details, UsersState, getUsersState } from '../../store/actions/users';
import { getRoute, RoutePathName } from '../../routes';

// Helpers
import { shortCivility } from '../../helpers/frenchConversion';
import { frCurrencyFormat } from '../../helpers/currency';
import { usePrevious } from '../../hooks';
import { downloadFile } from '../../helpers';

// Style
import '../../assets/styles/PurchaseList.less';

export interface PurchasesListProps {
    purchases: PurchasesState;
    users: UsersState;
    loadPurchases: typeof list.trigger;
    loadUsers: typeof details.trigger;
    getInvoice: typeof invoice.trigger;
}

const PurchasesList: FC<PurchasesListProps> = ({
    purchases, users, loadPurchases, loadUsers, getInvoice,
}) => {

    const [idToDonwload, setIdToDonwload] = useState<string>('');

    const rowKey = (item: PurchaseApiType) => `${item.id}`;

    const columns: Array<ColumnProps<PurchaseApiType>> = [
        {
            title: 'Date',
            dataIndex: 'succeededAt',
            render: (succeededAt: string) => {
                if (!succeededAt) {
                    return '-';
                }
                return moment(succeededAt).format('DD/MM/YYYY');
            },
            sorter: true,
        },
        { title: 'Numéro de commande', dataIndex: 'reference', sorter: true },
        {
            title: 'N° de transaction Stripe',
            dataIndex: 'paymentIntent',
            key: 'paymentIntentId',
            render: (paymentIntent: any) => {
                return paymentIntent ? paymentIntent.id : '-';
            },
            sorter: true,
        },
        { title: 'Articles', dataIndex: 'numberOfItems' },
        {
            title: 'Montant',
            dataIndex: 'price',
            render: (price: any) => {
                return frCurrencyFormat(price);
            },
            sorter: true,
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (purchaseId: string) => {
                const component = (
                    <div>
                        <ButtonLink
                            className="actions-buttons"
                            shape="circle"
                            to={getRoute(RoutePathName.purchasesDetails, { id: purchaseId })}
                        >
                            <EyeOutlined />
                        </ButtonLink>
                        <Button
                            onClick={onInvoiceDownload.bind(null, purchaseId)}
                            className="actions-buttons"
                            shape="circle"
                            icon={<DownloadOutlined className="invoice-button" />}
                        />
                    </div>
                );
                return component;
            },
        },
    ];

    const previous = usePrevious(purchases.invoice);

    const onInvoiceDownload = (invoiceId: PurchaseApiType['id']) => {
        setIdToDonwload(invoiceId);
        getInvoice(invoiceId);
    };

    const { id } = useParams<{ id: string }>();

    const user = users.details.data[id];

    const onTableChange: TableProps<PurchaseApiType>['onChange'] = (pagination, filters, sorter: any) => {
        let page = 0;
        if (pagination.current) {
            page = pagination.current - 1;
        }
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        if (!sortOrder || !sortOrder.length) {
            loadPurchases({
                userId: id,
                page,
                pageSize: pagination.pageSize,
            });
        } else {
            loadPurchases({
                userId: id,
                sort: sorter.field,
                sortOrder,
                page,
                pageSize: pagination.pageSize,
            });
        }
    };

    useEffect(() => {
        loadUsers({ userId: id });
        loadPurchases({ userId: id });
    }, [loadUsers, loadPurchases, id]);

    useEffect(() => {
        if (!purchases.invoice.loading && previous?.loading && !purchases.invoice.error) {
            const filename = `Facture_${purchases.list.data.items.find((e) => e.id === idToDonwload)?.reference}​​​​​`;
            console.log('filename :', filename);
            downloadFile(purchases.invoice.data, filename);
        }
    }, [purchases.invoice, purchases.list, previous, idToDonwload]);

    return (
        <>
            <Seo title="Liste des transactions" />
            <div className="purchases-list-header">
                <Divider className="purchases-list-header-divider"/>
                <Row>
                    <Col flex="62px" className="return-button">
                        <ButtonLink
                            shape="circle"
                            to={getRoute(RoutePathName.users)}
                        >
                            <ArrowLeftOutlined />
                        </ButtonLink>
                    </Col>
                    <Col flex="auto">
                        <Typography.Title level={1}>
                            {user?.firstName} {user?.lastName} ({shortCivility(user?.gender || '')})
                        </Typography.Title>
                        <Row>
                            <Col span={8}>
                                <p className="properties">Adresse e-mail :</p>
                                <p>{user?.email}</p>
                            </Col>
                            <Col span={6}>
                                <p className="properties">Téléphone portable :</p>
                                <p>{user?.mobilePhone}</p>
                            </Col>
                            <Col span={8}>
                                <p className="properties">Accepte les communications de LYR :</p>
                                <p>{user?.optin ? 'Oui' : 'Non'}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Table<PurchaseApiType>
                className="purchases-list-table"
                columns={columns}
                rowKey={rowKey}
                loading={purchases.list.loading}
                dataSource={purchases.list.data.items}
                pagination={false}
                onChange={onTableChange}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    purchases: getPurchasesState(state),
    users: getUsersState(state),
});

export default connect(
    mapStateToProps,
    {
        loadPurchases: list.trigger,
        loadUsers: details.trigger,
        getInvoice: invoice.trigger,
    },
)(PurchasesList);
