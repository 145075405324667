import React, { FC, useEffect } from 'react';

// ANTD
import { Row, Col, Card, Statistic } from 'antd';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import { mainStats, StatsState, getStatsState } from '../../../store/actions/stats';

// Helpers
import { frCurrencyFormat } from '../../../helpers/currency';

// Style
import '../../../assets/styles/MainStats.less';

export interface MainStatsProps {
    stats: StatsState;
    loadStats: typeof mainStats.trigger;
}

const MainStats: FC<MainStatsProps> = ({
    stats, loadStats,
}) => {

    useEffect(() => {
        loadStats();
    }, [loadStats]);

    return (
        <>
            {/* Price Stats  */}
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic title={`CHIFFRE D'AFFAIRE CUMULE`} value={`${frCurrencyFormat(stats.mainStats.data.turnover)}`} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic title={`CHIFFRE D'AFFAIRE`} value={`${frCurrencyFormat(stats.mainStats.data.turnoverOfMonth)}`} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic
                            title={`PRIX MOYEN PAR PANIER`}
                            value={`${frCurrencyFormat(stats.mainStats.data.cartAverageOfMonth)}`}
                        />
                    </Card>
                </Col>
            </Row>
            {/* Number Stats */}
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic title={`NOMBRE DE COMPTES CREES`} value={`${stats.mainStats.data.numberOfUserCreated}`} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic
                            valueStyle={{ color: '#cf1322' }}
                            title={`NOMBRES DE COMPTES SUPPRIMES`}
                            value={`${stats.mainStats.data.numberOfUserDeleted}`}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className="turnover-stats" >
                        <Statistic title={`NOMBRE DE FACTURE EMISES`} value={`${stats.mainStats.data.numberOfInvoice}`} />
                    </Card>
                </Col>
            </Row>
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    stats: getStatsState(state),
});

export default connect(
    mapStateToProps,
    {
        loadStats: mainStats.trigger,
    },
)(MainStats);
