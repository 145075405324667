import { combineReducers } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Purchase, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    PurchaseDetailsPayload,
    PurchaseListPayload,
    details as detailsApiCall,
    list as listApiCall,
    invoice as invoiceApiCall,
    PurchaseIdPayload,
} from '../api/purchases';
import { DataAction } from '../helpers/EzeeAction';

// State

export interface PurchasesState {
    details: RequestState<{ [id: string]: Purchase }>;
    list: RequestState<ListResponse<Purchase>>;
    invoice: RequestState<string>;
}

const initialState: PurchasesState = {
    details: {
        data: {},
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    invoice: {
        data: '',
        loading: false,
    },
};

// Actions/Reducers

export const details = new EzeeAsyncAction<
    PurchasesState['details'],
    PurchaseDetailsPayload,
    Purchase
>('purchases/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const invoice = new EzeeAsyncAction<
    PurchasesState['invoice'],
    PurchaseIdPayload,
    string
>('purchases/invoice', initialState.invoice, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.invoice,
    }),
});

export const list = new EzeeAsyncAction<
    PurchasesState['list'],
    PurchaseListPayload,
    ListResponse<Purchase>
>('purchases/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

// Reducer

export const purchasesReducer = combineReducers<PurchasesState>({
    details: details.reducer,
    invoice: invoice.reducer,
    list: list.reducer,
});

// Saga

export function* purchasesSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(invoice.type.trigger, invoiceFileSaga);
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

function* invoiceFileSaga(actionData: DataAction<PurchaseIdPayload>) {
    try {
        const response = yield call(invoiceApiCall, actionData.payload);
        const blob = new Blob([response], { type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        return yield put(invoice.success(url));
    } catch (error) {
        return yield put(invoice.failure(error));
    }
}

// Store helpers

export const getPurchasesState = (state: MainReducerState) => state.purchases;
