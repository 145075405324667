import { SearchPaginationQuery } from './';
import api from './_client';
import { Editor } from './apiTypes';

export type EditorsListPayload = SearchPaginationQuery;

export type EditorsIdPayload = Editor['id'];

export interface EditorsUpdatePayload {
    id: EditorsIdPayload;
    body: {
        name: Editor['name'],
        email: Editor['email'],
    };
}

export interface EditorsCreatePayload {
    body: {
        name: Editor['name'],
        email: Editor['email'],
    };
}

export const create = (payload: EditorsCreatePayload) => {
    return api.post(`/editors`, payload.body);
};

export const list = (payload: EditorsListPayload = {}) => {
    return api.get(`/editors`, { params: payload });
};

export const update = (payload: EditorsUpdatePayload) => {
    return api.put(`/editors/${payload.id}`, payload.body);
};

export const remove = (payload: EditorsIdPayload) => api.delete(`/editors/${payload}`);
