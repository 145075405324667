import { SearchPaginationQuery } from './';
import api from './_client';
import {Promo} from './apiTypes';
export interface PromoCreatePayload {
    webSerie: string;
    company: string;
    discount: number;
    quantity: number;
    quantityUsed: number;
    expirationStartDate: string;
    expirationEndDate: string;
}

export interface PromoUpdatePayload {
    id: string;
    data: {
        quantity: number,
        expirationDate: Date,
    };
}

export type PromoIdPayload = Promo['id'];

export type PromoListPayload = SearchPaginationQuery;

export const list = (payload: PromoListPayload = {}) => {
    return api.get('/promo', {params: payload});
};

export const details = (payload: PromoIdPayload) => api.get(`/promo/${payload}`);

export const remove = (payload: PromoIdPayload) => api.delete(`/promo/${payload}`);

export const create = (payload: PromoCreatePayload) => api.post('/promo', payload);

export const update = (payload: PromoUpdatePayload) => api.put(`/promo/${payload.id}`, payload.data);

export const exportPromo = () => api.get(`/promo/export`, { responseType: 'blob' });
