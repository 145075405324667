import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { Category, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';

import {
    CategoryIdPayload,
    CategoryCreatePayload,
    CategoriesListPayload,
    CategoryUpdatePayload,
    create as createApiCall,
    list as listApiCall,
    update as updateApiCall,
    remove as removeApiCall,
} from '../api/categories';
// State

export interface CategoriesState {
    list: RequestState<ListResponse<Category>>;
    update: RequestState<Category>;
    create: RequestState<Category>;
    remove: RequestState<Category>;
}

const initialState: CategoriesState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    create: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    remove: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
};

// Actions/Reducers

export const list = new EzeeAsyncAction<
    CategoriesState['list'],
    CategoriesListPayload,
    ListResponse<Category>
>('categories/list', initialState.list, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.list,
    }),
});

export const update = new EzeeAsyncAction<
    CategoriesState['update'],
    CategoryUpdatePayload,
    Category
>('categories/update', initialState.update, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.update,
    }),
});

export const create = new EzeeAsyncAction<
    CategoriesState['create'],
    CategoryCreatePayload,
    Category
>('categories/create', initialState.create, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: undefined,
    }),
    reset: (state) => ({
        ...initialState.create,
    }),
});

export const remove = new EzeeAsyncAction<
    CategoriesState['remove'],
    CategoryIdPayload,
    Category
>('categories/remove', initialState.remove, {
    trigger: (state) => ({
        ...state,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, payload) => ({
        ...state,
        categories: payload,
        loading: false,
        success: true,
        error: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.remove,
    }),
});

// Reducer

export const categoriesReducer = combineReducers<CategoriesState>({
    list: list.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
});

// Saga

export function* categoriesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
}

// Store helpers

export const getCategoriesState = (state: MainReducerState) => state.categories;
export const getCategoriesCreateState = (state: MainReducerState) => state.categories.create;
export const getCategoriesUpdateState = (state: MainReducerState) => state.categories.update;
export const getCategoriesRemoveState = (state: MainReducerState) => state.categories.remove;
export const getCategoriesListState = (state: MainReducerState) => state.categories.list;
