import React, { FC, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Spin } from 'antd';

import { AuthState, login, checkLoginStatus as checkLoginStatusAction, getAuthState } from '../store/actions/auth';
import { MainReducerState } from '../store/reducers';

interface ProtectedRouteProps {
    auth: AuthState;
    checkLoginStatus: typeof checkLoginStatusAction.trigger;
    children: ReactNode;
    loginPath: string;
    logout: typeof login.failure;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    auth, checkLoginStatus, children, loginPath, logout,
}) => {
    useEffect(() => {
        // dispatched from axios interceptor in api/_client.ts
        window.addEventListener('unauthorized.error', logout);

        if (!auth.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', logout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (auth.hasCheckedLoginStatus && !auth.isConnected) {
        return <Redirect to={loginPath} />;
    } else if (auth.hasCheckedLoginStatus && auth.isConnected) {
        return <>{children}</>;
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    }
};

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuthState(state),
});

export default connect(
    mapStateToProps,
    {
        checkLoginStatus: checkLoginStatusAction.trigger,
        logout: login.failure,
    },
)(ProtectedRoute);
