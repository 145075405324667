import { SearchPaginationQuery } from './';
import api from './_client';

export interface StatsParam {
    isDeleted?: boolean;
}

export type WebSeriesStatsPayload = SearchPaginationQuery & StatsParam;

export type EditorsStatsPayload = SearchPaginationQuery & StatsParam;

export const mainStats = () => api.get(`/stats`);

export const webSeriesStats = (payload: WebSeriesStatsPayload = {}) => {
    return api.get(`/webSeriesStats`, { params: payload });
};

export const editorsStats = (payload: EditorsStatsPayload = {}) => {
    return api.get(`/editorsStats`, { params: payload });
};

export const exportStats = () => api.get(`/exportStats`, { responseType: 'blob' });
