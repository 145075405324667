import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

// ANTD
import { Row, Col, Card, Typography, Table, Switch } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

// API Types
import { WebSerie as WebSerieApiType } from '../../../store/api/apiTypes';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../../store/reducers';
import { webSeriesStats, StatsState, getStatsState } from '../../../store/actions/stats';

// Helpers
import { frCurrencyFormat } from '../../../helpers/currency';

// Style
import '../../../assets/styles/WebSeriesStats.less';

const pageSize = 5;

const dateKey = moment().format('YYYY-MM');

const rowKey = (item: WebSerieApiType) => `${item.id}`;

const columns: Array<ColumnProps<WebSerieApiType>> = [
    { key: 'id' },
    { title: 'Web-série', dataIndex: 'name', sorter: true},
    { title: `Nombre d'épisodes`, dataIndex: 'numberOfVideos'},
    {
        title: `Chiffre d'affaires`,
        dataIndex: 'stats',
        key: 'turnover',
        sorter: true,
        render: (stats: any) => {
            const key = `${dateKey}`;
            if (stats && stats[key]) {
                return `${frCurrencyFormat(stats[key].turnover)}`;
            }
            return `${frCurrencyFormat(0)}`;
        },
    },
    {
        title: 'Nombre de vues',
        dataIndex: 'stats',
        key: 'numberOfViews',
        sorter: true,
        render: (stats: any) => {
            const key = `${dateKey}`;
            if (stats && stats[key]) {
                return `${stats[key].numberOfViews}`;
            }
            return `0`;
        },
    },
];

const expandedRowRender = (record: any) => {
    const subColumns = [
        {
            title: 'Episode',
            dataIndex: 'title',
            sorter: (a: any, b: any) => a.title.localeCompare(b.title),
        },
        {
            title: `Chiffre d'affaires`,
            dataIndex: 'stats',
            key: 'turnover',
            render: (stats: any) => {
                const key = `${dateKey}`;
                if (stats[key]) {
                    return `${frCurrencyFormat(stats[key].turnover)}`;
                }
                return `${frCurrencyFormat(0)}`;
            },
            sorter: (a: any, b: any) => {
                const key = `${dateKey}`;
                if (a[key] && b[key]) {
                    return a[key].turnover - b[key].turnover;
                }
                return 1;
            },
        },
        {
            title: 'Nombre de vues',
            dataIndex: 'stats',
            key: 'numberOfViews',
            render: (stats: any) => {
                const key = `${dateKey}`;
                if (stats[key]) {
                    return `${stats[key].numberOfViews}`;
                }
                return `0`;
            },
            sorter: (a: any, b: any) => {
                const key = `${dateKey}`;
                if (a[key] && b[key]) {
                    return a[key].numberOfViews - b[key].numberOfViews;
                }
                return 1;
            },
        },
    ];
    const expandedRow = <Table columns={subColumns} dataSource={record.videos} pagination={false} rowKey={'id'}/>;
    return expandedRow;
};

export interface WebSeriesStatsProps {
    stats: StatsState;
    loadStats: typeof webSeriesStats.trigger;
}

const WebSeriesStats: FC<WebSeriesStatsProps> = ({
    stats, loadStats,
}) => {
    const [onlyVimeo, setOnlyVimeo] = useState(true);
    const onTableChange: TableProps<WebSerieApiType>['onChange'] = (pagination, filter, sorter: any) => {
        let sort = '';
        if (sorter.field === 'name') {
            sort = sorter.field;
        } else if (sorter.columnKey === 'turnover') {
            sort = `${sorter.field}.${dateKey}.${sorter.columnKey}`;
        } else if (sorter.columnKey === 'numberOfViews') {
            sort = `${sorter.field}.${dateKey}.${sorter.columnKey}`;
        }
        const sortOrder = sorter.order === 'ascend' ? 'asc' : (sorter.order === 'descend' ? 'desc' : '');
        let page = 0;
        if (pagination.current) {
            page = pagination.current - 1;
        }
        if (!sortOrder || !sortOrder.length) {
            loadStats({
                isDeleted: onlyVimeo === false ? undefined : false,
                page,
                pageSize: pagination.pageSize,
            });
        } else {
            loadStats({
                isDeleted: onlyVimeo === false ? undefined : false,
                page,
                pageSize: pagination.pageSize,
                sort,
                sortOrder,
            });
        }
    };
    useEffect(() => {
        loadStats({
            isDeleted: onlyVimeo === false ? undefined : false,
        });
    }, [loadStats, onlyVimeo]);

    const onVimeoChange = (checked: boolean) => {
        setOnlyVimeo(checked);
    };

    return (
        <>
            <Card className="webseries-stats">
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <Typography.Title level={4} className="title">WEB-SERIES & EPISODES</Typography.Title>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[24, 0]}>
                            <Col><Typography.Title level={4} className="title">Seulement vimeo</Typography.Title></Col>
                            <Col><Switch defaultChecked onChange={onVimeoChange}/></Col>
                        </Row>
                    </Col>
                    <Col span={8} className="total-views-number">
                        <Typography.Title level={4} className="name">Nombre de vues total</Typography.Title>
                        <Typography.Title level={2} className="value">{stats.mainStats.data.numberOfViewsOfMonth}</Typography.Title>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Table<WebSerieApiType>
                            className="components-table-demo-nested"
                            columns={columns}
                            rowKey={rowKey}
                            loading={stats.webSeriesStats.loading}
                            dataSource={stats.webSeriesStats.data.items}
                            pagination={{
                                total: stats.webSeriesStats.data.totalCount,
                                current: stats.webSeriesStats.data.page + 1,
                                pageSize,
                                position: ['bottomCenter'],
                            }}
                            expandable={{ expandedRowRender }}
                            onChange={onTableChange}
                        />
                    </Col>
                </Row>
            </Card>
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    stats: getStatsState(state),
});

export default connect(
    mapStateToProps,
    {
        loadStats: webSeriesStats.trigger,
    },
)(WebSeriesStats);
