import React, { FC, useEffect, useState } from 'react';
import Seo from '../../components/Seo';

// ANTD
import { Divider, Typography, Form, Space, Spin } from 'antd';

// Store
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import {
    upload,
    remove,
    details,
    LandingState,
    getLandingUploadState,
    getLandingDetailsState,
} from '../../store/actions/landing';

// Helpers
import { usePrevious } from '../../hooks';
import { ButtonProps } from 'antd/lib/button';

// Style
import '../../assets/styles/Landing.less';
import { FormProps } from 'antd/lib/form';

// Components
import { PartnerDrawer } from './PartnerDrawer';
import { Partner } from '../../store/api/apiTypes';
import { Banner } from './Banner';
import { MarketingBanner } from './MarketingBanner';
import { Partners } from './Partners';
import { HeadLine } from './Headline';
import { Blocks } from './Blocks';
import { LandingValidation } from './LandingValid';

export interface LandingProps {
    uploadLandingState: LandingState['upload'];
    detailsLandingState: LandingState['details'];
    uploadLanding: typeof upload.trigger;
    detailsLanding: typeof upload.trigger;

}

const Landing: FC<LandingProps> = ({
    uploadLandingState, detailsLandingState, uploadLanding, detailsLanding,
}) => {
    const [form] = Form.useForm();
    const [items, setItems] = useState<Partner[]>();
    const [indexToEdit, setIndexToEdit] = useState<number>();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [block1, setBlock1] = useState<boolean>(false);
    const [block2, setBlock2] = useState<boolean>(false);
    const [block3, setBlock3] = useState<boolean>(false);

    const previous = usePrevious({
        detailsLandingState,
        uploadLandingState,
    });

    const addPartner: ButtonProps['onClick'] = (e) => {
        setIndexToEdit(undefined);
        setIsVisible(true);
        return false;
    };

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const logos = items?.filter((item) => !!item.logoFile).map((item) => item.logo);
        uploadLanding({
            body: {
                ...values,
                partners: items?.map((item) => ({...item, logo: item.logo.id})),
                logos,
            },
        });
    };

    const onEditPartner = (value: number) => {
        setIndexToEdit(value);
        setIsVisible(true);
    };

    const onRemovePartner = (value: number) => {
        if (items) {
            const newItems = [...items];
            newItems.splice(value, 1);
            setItems(newItems);
        }
    };

    const onClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (previous?.uploadLandingState.loading && !uploadLandingState.loading) {
            if (!uploadLandingState.error && uploadLandingState.data) {
                detailsLanding();
            }
        }
    }, [
        previous,
        uploadLandingState,
        detailsLandingState.data,
        form,
        detailsLanding,
    ]);

    useEffect(() => {
        if (previous?.detailsLandingState.loading && !detailsLandingState.loading) {
            if (!detailsLandingState.error && detailsLandingState.data) {
                setBlock1(detailsLandingState.data?.blocks[0].display ?? false);
                setBlock2(detailsLandingState.data?.blocks[1].display ?? false);
                setBlock3(detailsLandingState.data?.blocks[2].display ?? false);
                setItems(detailsLandingState.data?.partners);
                form.setFieldsValue({
                    ...detailsLandingState.data,
                    backgroundImage: detailsLandingState.data?.marketingBanner.backgroundImage,
                    bannerImage: detailsLandingState.data?.banner.backgroundImage,
                    image1: detailsLandingState.data?.blocks[0].image,
                    image2: detailsLandingState.data?.blocks[1].image,
                    image3: detailsLandingState.data?.blocks[2].image,
                    headlineImage1: detailsLandingState.data?.headline.block1.image,
                    headlineImage2: detailsLandingState.data?.headline.block2.image,
                    headlineImage3: detailsLandingState.data?.headline.block3.image,
                });
            }
        }
    }, [previous, detailsLandingState, form]);

    useEffect(() => {
        detailsLanding();
    }, [detailsLanding]);

    return (
        <>
       <Seo title="Page d’Accueil" />
            <div className="page-header">
                <Divider />
                <Typography.Title level={1}>Page d’Accueil (Visiteurs non inscrits / non connectés)</Typography.Title>

            </div>
            <Spin spinning={detailsLandingState.loading}>
                <Form
                    form={form}
                    onFinish={onFormValidSubmit}
                    scrollToFirstError
                    layout="vertical"
                    requiredMark={false}
                    className="landing"
                >
                    <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: '32px' }}>
                        <LandingValidation uploadLandingState={uploadLandingState} />

                        <Banner
                            name={form.getFieldValue(['bannerImage', 'originalName'])}
                            url={form.getFieldValue(['bannerImage', 'url'])}
                        />

                        <Blocks
                            form={form}
                            block1={block1}
                            block2={block2}
                            block3={block3}
                            setBlock1={setBlock1}
                            setBlock2={setBlock2}
                            setBlock3={setBlock3}
                        />

                        <HeadLine form={form}/>

                        <MarketingBanner form={form}/>

                        <Partners
                            items={items}
                            setItems={setItems}
                            addPartner={addPartner}
                            onEditPartner={onEditPartner}
                            onRemovePartner={onRemovePartner}
                        />

                        <LandingValidation uploadLandingState={uploadLandingState} />
                    </Space>
                </Form>
                <PartnerDrawer
                    indexToEdit={indexToEdit}
                    items={items}
                    setItems={setItems}
                    onClose={onClose}
                    isVisible={isVisible}
                />
            </Spin>
        </>
      );
};

const mapStateToProps = (state: MainReducerState) => ({
    uploadLandingState: getLandingUploadState(state),
    detailsLandingState: getLandingDetailsState(state),
});

export default connect(
    mapStateToProps,
    {
        uploadLanding: upload.trigger,
        detailsLanding: details.trigger,
        removeLanding: remove.trigger,
    },
)(Landing);
