
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React, { FC } from 'react';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd';
import { Partner } from '../../store/api/apiTypes';

export interface PartnersProps {
    items?: Partner[];
    setItems: (value: any) => void;
    addPartner: (value: any) => void;
    onEditPartner: (value: any) => void;
    onRemovePartner: (value: any) => void;
}

export const Partners: FC<PartnersProps> = ({
    items, setItems, addPartner, onEditPartner, onRemovePartner,
}) => {

    const reorder = (list: Partner[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
        if (items && result.destination) {
            const newItems = reorder(
                items,
                result.source.index,
                result.destination.index,
            );
            setItems(newItems.map((item, i) => ({...item, index: i})));
        }
    };

    return (
        <>
            <Typography.Title className="title" level={2}>Partenaires</Typography.Title>
            <Card className="landing-card">
                <div className="flex" style={{ marginBottom: '24px'}}>
                    <Button className="add-new" onClick={addPartner} style={{ width: '128px' , height: '128px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <PlusOutlined style={{ fontWeight: 'bold', marginBottom: '12px' }} />
                            <span style={{ fontWeight: 'bold'}}>
                                Ajouter
                            </span>
                        </div>
                    </Button>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <div
                                className="draggable-upload"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                            {items?.map((item, index) => (
                                <Draggable key={item.name} draggableId={item.name} index={index}>
                                {(draggableProvided) => (
                                    <div
                                        className="draggable-upload-item"
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                    >
                                        <img
                                            style={{
                                                width: '128px',
                                                height: '128px',
                                                borderRadius: '6px',
                                            }}
                                            src={item?.logo ?
                                                    item?.logo?.url ? item?.logo?.url : URL.createObjectURL(item?.logo)
                                                : undefined}
                                            alt="new"
                                        />
                                        <div>
                                            <Button
                                                type="link"
                                                onClick={onEditPartner.bind(null, index)}
                                                icon={<EditOutlined/>}
                                            />
                                            <Button
                                                type="link"
                                                onClick={onRemovePartner.bind(null, index)}
                                                icon={<DeleteOutlined/>}
                                            />
                                        </div>
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>

                </div>
            </Card>
        </>
        );
};
